// variables
@import "../css/Variables/Variables.scss";
//Variables end

.header-drop-down-popup {
    background: #ffffff;
    border: 1px solid rgba(210, 218, 227, 0.75);
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    padding: 0;
    list-style: none;
    margin-top: 0px;
    overflow: hidden;
    min-width: inherit;
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% top;
    transform-origin: 100% top;
    -webkit-transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s, -webkit-transform 0.12s linear 0.12s;
    transition: opacity 0.12s linear 0.12s, visibility 0.12s linear 0.12s, transform 0.12s linear 0.12s, -webkit-transform 0.12s linear 0.12s;
    visibility: hidden;
}
.header-drop-down-popup-hover {
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
}
.category-list-popup {
    margin-left: -21px;
    min-width: 180px;
    padding: 15px 0 0 0;
    .k-popup {
        padding: 6px 0 10px 5px;
        box-shadow: 0px 20px 30px rgba(145, 148, 165, 0.25);
        border-radius: 0 0 6px 6px;
        border: none;
    }
}
.search-auto-complete-list {
    .k-popup {
        box-shadow: 0px 20px 30px rgba(145, 148, 165, 0.25);
        border-radius: 0 0 6px 6px;
        border: none;
    }
}
.suggestions-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 0 5px;
}
p.suggestion-left,
p.suggestion-right {
    font-size: 16px;
    cursor: pointer;
    margin: 0;
}
p.suggestion-left {
    color: $text-dark-black-color;
    font-weight: 500;
}
p.suggestion-right {
    color: $label-color;
    padding-right: 5px;
}

p.suggestion-left:hover {
    color: $primary-light-blue;
}

.main-header {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(97, 112, 136, 0.12);
    .header-wrapper {
        display: flex;
        justify-content: space-between;
        .logo-section {
            width: 210px;
            padding: 0px 24px 10px 10px;
            background: #ffffff;
            display: block;
            height: 72px;

            background: none;
            border: none;
            color: #fff;
            font-family: Verdana, Geneva, sans-serif;
            cursor: pointer;

            a {
                border: none;
                text-decoration: none;
                img {
                    width: 100%;
                }
            }
            a.jet-logo {
                width: 174px;
                float: left;
                margin: -3px 0 0 -20px;
                cursor: pointer;
            }
            a.phoenix-logo {
                width: 103px;
                height: 20px;
                float: right;
                margin-top: -18px;
            }
        }
        .middle-section {
            display: flex;
            align-content: center;
            justify-content: space-between;
            border-right: 1px solid #eef0f4;
            border-left: 1px solid #eef0f4;
            padding: 0 5px 0 5px;
            width: calc(100% - 390px);
            position: relative;
            .search-icon {
                display: flex;
                align-items: center;
                margin-left: 25px;
            }
            .search-form {
                width: calc(100% - 60px);
                padding-left: 15px;
                align-items: center;
                .k-input {
                    height: 100%;
                    padding: 0 10px 0 0px;
                    border: none;
                    font-size: 16px;
                    font-weight: 300;
                    color: #667080bf;
                    font-family: "League Spartan", sans-serif;
                    box-shadow: none;
                    &:focus,
                    &:hover,
                    &:active,
                    .k-focus {
                        box-shadow: none;
                        outline: none;
                    }
                    .k-input-inner {
                        padding: 0;
                        color: #00041b;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 15px;
                        &:focus,
                        &:hover,
                        &:active,
                        .k-focus {
                            box-shadow: none;
                            outline: none;
                        }
                        &::placeholder{
                            color: rgba(102, 112, 128, 0.75);
                        }
                    }
                }
                .select-category-options {
                    border-right: 1px solid #e1e4ea;
                    min-width: 160px;
                    padding-right: 15px;
                    height: 40px;
                    &:focus,
                    &:hover,
                    &:active {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .notification-btn {
                background: url("/assets/images/bell-blue.svg");
                background-size: 17px !important;
            }
            .header-search-btn,
            .search-text {
                //background: url("/assets/images/search-blue.svg"); display: none;
                color: $primary-dark-blue;
                font-size: 16px;
                font-weight: 600;
                margin-top: 0px;
                background: none;
                &:focus {
                    box-shadow: none;
                }
            }
            .menu-icon-btn {
                background: url("/assets/images/burgerMenuIcon.svg");
                display: none;
            }
            .header-Close-btn {
                background: url("/assets/images/close-blue.svg");
                display: none;
            }
            .notification-btn,
            .header-search-btn,
            .menu-icon-btn,
            .header-close-btn {
                background-repeat: no-repeat;
                height: 100%;
                background-position: center;
                border: none;
                background-size: 22px;
                width: 60px;
                padding: 0;
                &:focus,
                &:hover,
                &:active {
                    box-shadow: none;
                    outline: none;
                }
            }
            .notification-btn {
                position: relative;
                min-height: 72px;
                .k-badge {
                    background: $error-color;
                    border: 2px solid #ffffff;
                    width: 18px;
                    height: 18px;
                    min-width: inherit;
                    font-size: 10px;
                    border-radius: 10px;
                    left: 25px;
                    top: 27px;
                    padding: 0;
                }
            }
            .k-multiselect {
                background: url("/assets/images/search-blue.svg"), url("/assets/images/down-angle-blue.svg");
                background-position: center left, center right;
                background-repeat: no-repeat !important;
                background-size: 15.75px, 10px;
                padding: 0 15px 0 22px !important;
            }
            .k-focus .k-multiselect {
                background: url("/assets/images/search-blue.svg"), url("/assets/images/up-angle-grey.svg");
                background-position: center left, center right;
                background-repeat: no-repeat !important;
                background-size: 15.75px, 10px;
            }
        }
    }
    .user-sec {
        width: 180px;
        padding-left: 20px;
        padding-right: 10px;
        .dropdown {
            padding: 0;
            .k-button-outline {
                color: $primary-dark-blue;
                padding: 15px 18px 15px 0;
                box-shadow: none !important;
                cursor: pointer;
                font-weight: 500;
                background-image: url("/assets/images/down-angle-blue.svg");
                background-size: 13px;
                background-repeat: no-repeat;
                border: none;
                transition: 300ms ease all;
                max-width: fit-content;
                background-position: right center;
                &::after,
                &::before {
                    box-shadow: none;
                    outline: none;
                    background-color: transparent;
                }
                &:hover,
                &:focus,
                &:active {
                    box-shadow: none;
                    outline: none;
                    background-color: transparent;
                    &::after,
                    &::before {
                        box-shadow: none;
                        outline: none;
                        background-color: transparent;
                    }
                }
                .k-button-text {
                    display: flex;
                    max-width: 100%;
                    .user-pic {
                        span {
                            width: 40px;
                            height: 40px;
                            background: #e6e9f7;
                            color: $primary-dark-blue;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 1px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                        }
                        img {
                            width: 40px;
                            height: 40px;
                            overflow: hidden;
                            border-radius: 100%;
                            object-fit: cover;
                        }
                    }
                    .user-name-sec {
                        padding-left: 12px;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        letter-spacing: 0.5px;
                        span {
                            font-weight: 600;
                            font-size: 10px;
                            color: #95a1af;
                            text-overflow: ellipsis;
                            width: 86px;
                            overflow: hidden;
                        }
                        strong {
                            color: $primary-dark-blue;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 15px;
                            text-overflow: ellipsis;
                            width: 86px;
                            overflow: hidden;
                        }
                    }
                }
            }
            .dropdown-menu {
                right: -8px;
                @extend .header-drop-down-popup;
                li {
                    a {
                        color: $primary-dark-blue;
                        font-weight: 600;
                        font-size: 15px;
                        letter-spacing: 0.3px;
                        padding: 8px 25px;
                        display: inline-block;
                        width: 100%;
                        &:hover {
                            background-color: #f7f8f9;
                        }
                    }
                }
            }
            &:hover {
                .k-button-outline {
                    background-image: url("/assets/images/up-angle-blue.svg");
                }
                .dropdown-menu {
                    @extend .header-drop-down-popup-hover;
                }
            }
        }
    }
}

.notification-sec {
    .dropdown {
        padding: 0;
        .dropdown-menu {
            @extend .header-drop-down-popup;
            box-shadow: 0px 3.28182px 24.6136px rgba(127, 135, 146, 0.15);
            border-radius: 3.28182px;
            border: none;
            width: 360px;
            right: -60px;
            overflow: inherit;
            &:after {
                bottom: 100%;
                right: 80px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #fff;
                border-width: 10px;
                margin-left: -10px;
            }
            .notification-title-sec {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 16px 8px 16px;
                background: #fff;
                //border-bottom: 1px solid #E4E8EE;
                h5 {
                    font-weight: 500;
                    font-size: 14.7682px;
                    line-height: 16px;
                    color: #1a1f36;
                    margin: 0;
                }
                .read-unread-switch {
                    display: flex;
                    align-items: center;
                    label {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        color: $dark-grey;
                        padding-left: 5px;
                    }
                }
                .btn-link {
                    color: $primary-light-blue;
                    font-weight: 600;
                    text-decoration: none;
                    background-image: url("/assets/images/check-circle-blue.svg");
                    background-repeat: no-repeat;
                    background-position: center right;
                    background-size: 12px;
                    padding-right: 17px;
                }
            }
            .notification-scroll-sec {
                min-height: 150px;
                max-height: 400px;
                overflow-y: auto;
            }
            .all-notification {
                padding: 15px 10px 5px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-link {
                    text-decoration: none;
                }
            }
        }
        &:hover {
            .dropdown-menu {
                @extend .header-drop-down-popup-hover;
            }
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            &:hover {
                background: #f3f5ff;
            }
            border-bottom: 1px solid #e4e8ee;
            padding: 15px;
            display: flex;
            align-items: flex-start;
            gap: 15px;
            cursor: pointer;
            .user-pic-sec {
                position: relative;
                min-width: 30px;
                .k-badge {
                    background: $primary-light-blue;
                    border: none;
                    width: 7px;
                    height: 7px;
                    left: -10px;
                    top: 0px;
                    padding: 0;
                    z-index: 9;
                }
                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                }
            }
            .user-data-sec {
                width: calc(100% - 45px);
                h6 {
                    font-weight: 500;
                    font-size: 14.1267px;
                    line-height: 18px;
                    color: $dark-grey;
                    margin-bottom: 8px;
                }
                .notification-des {
                    position: relative;
                    padding-left: 12px;
                    margin-bottom: 12px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #000837;
                    &::before {
                        content: "";
                        width: 4px;
                        background: #dddee1;
                        border-radius: 10px;
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
                .date-time {
                    font-weight: 500;
                    font-size: 12.3608px;
                    line-height: 18px;
                    color: #b4b6c3;
                    display: block;
                }
            }
        }
    }
}

.notification-list-wrapper {
    max-width: 880px;
    margin: 0 auto;
    padding-top: 10px;
    h1 {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #122a46;
    }
    .notification-sec {
        ul {
            // padding-right: 0;
            li {
                background: #ffffff;
                border: 0.5px solid rgba(210, 218, 227, 0.75);
                border-radius: 5px;
                margin-top: 15px;
                margin-bottom: 5px;
            }
        }
    }
}

// Multiselect box
.k-multiselect {
    .k-selection-multiple {
        gap: 4px;
        padding: 5px 0 5px 0;

        .k-chip-solid-base {
            border: none;
            color: $deep-blue;
            background-color: #e6e9f7;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            border-radius: 100px;
            letter-spacing: 0;
            padding: 7px 9px;

            .k-chip-content {
                padding: 0;
                margin-left: 0;
            }

            .k-chip-actions {
                margin: 0;

                .k-chip-remove-action {
                    .k-i-x-circle {
                        background-image: url("/assets/images/close-blue.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 100%;
                        height: 6.3px;
                        width: 6.3px;
                        margin-left: 2px;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .k-clear-value {
        display: none;
    }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
    .main-header {
        z-index: 999;
        .header-wrapper {
            position: relative;
            .logo-section {
                width: 155px;
                padding: 1px 0 0 0;
                height: 60px;
                a.jet-logo {
                    width: 140px;
                    margin: 0px 0 0 -15px;
                }
                a.phoenix-logo {
                    margin-right: 0;
                    width: 83.42px;
                }
            }
            .user-sec {
                display: none;
            }
            .middle-section {
                border: none;
                padding: 0;
                width: auto;
                .search-form {
                    position: absolute;
                    left: -12px;
                    background: #fff;
                    bottom: 0;
                    top: 0;
                    width: calc(100% + 12px);
                    z-index: 99999;
                    padding-left: 0px;
                    right: 0;
                    .k-input {
                        background-position: center left, center right;
                        background-size: 12.5px, 10px;
                        padding: 0 10px 0 15px !important;
                    }
                    .seach-overlay {
                        background: $dark-blue;
                        opacity: 0.3;
                        position: fixed;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 60px;
                        z-index: 99;
                    }
                    .select-category-options {
                        min-width: 100px;
                        padding-right: 10px;
                        height: 30px;
                        display: none;
                    }
                    .k-clear-value {
                        display: none;
                    }
                    .custom-input {
                        width: calc(100% - 80px);
                        background: url("/assets/images/search-blue.svg");
                        background-repeat: no-repeat;
                        background-position: left 15px center;
                        padding-left: 40px !important;
                        background-size: 15px;
                    }
                }
                .notification-btn,
                .header-search-btn,
                .menu-icon-btn,
                .header-close-btn {
                    width: 38px;
                    display: inline-block;
                    background-size: 20px;
                }
                .notification-btn {
                    min-height: 60px;
                    .k-badge {
                        left: 13px;
                        top: 24px;
                    }
                }
                .header-close-btn {
                    background-size: 14px;
                    position: absolute;
                    right: 4px;
                    top: 0;
                    width: 30px;
                }
            }
        }
    }
    .notification-list-wrapper {
        padding-top: 15px;
        h1 {
            font-size: 20px;
            margin-bottom: 0;
        }
        .notification-sec ul li {
            margin-top: 10px;
            margin-bottom: 12px;
        }
    }
    .notification-sec .dropdown .dropdown-menu {
        right: -40px;
        max-width: 340px;
        &:after {
            right: 50px;
        }
    }
    .search-auto-complete-list {
        min-width: 100%;
        left: 0 !important;
        z-index: 999 !important;
        border-top: 1px solid #e6e9f7;
        .k-popup {
            padding-top: 5px;
            .suggestions-list {
                padding: 0px;
                p.suggestion-left,
                p.suggestion-right {
                    font-size: 14px;
                }
            }
        }
    }
    .main-header .header-wrapper .middle-section .header-search-btn {
        background: url("/assets/images/search-blue.svg");
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 0;
    }
    .main-header .header-wrapper .middle-section .search-text {
        font-size: 14px;
        margin-top: 0px;
        position: fixed;
        z-index: 99999;
        right: 40px;
        top: 12px;
        padding-left: 5px;
        padding-right: 10px;
        &:focus {
            box-shadow: none;
        }
    }
}
