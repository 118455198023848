// variables
@import "../css/Variables/Variables.scss";
//Variables end


.main-custom-accordian {
    border: none;
    min-width: 1000px;
    .k-icon{
        margin-left: 8px !important;
    }
    .k-link{
        // padding: 8px 16px !important;
    }

    .k-panelbar {
        border-radius: 4px;
        border: none;
    }
    .k-panelbar-header {
        .k-link,
        .k-selected {
            background: rgba(238, 240, 248, 0.85) !important;
            border-radius: 4px;
            // padding: 13px 20px;
            border: none;

            .k-panelbar-item-text {
                width: 100%;

                .panel-title-sec {
                    display: flex;
                    align-items: center;

                    img {
                        width: 15px;
                    }

                    strong {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: $primary-dark-blue;
                        padding-left: 12px;
                    }
                }
            }

            .k-icon {
                color: #001489 !important;
                font-size: 13px;
                right: 20px;
                font-weight: 600;
                cursor: pointer;
                top: 2px;
            }
        }

        .k-link.k-focus {
            box-shadow: none;
        }
    }

    .accordian-box {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .items {
            padding: 0 8px;
            .label {
                font-size: 12px;
                line-height: 11px;
                font-weight: 400;
                color: #82869b;
            }
            .details {
                font-size: 14px;
                line-height: 13px;
                color: #000837;
            }
        }
    }

    .parent-custom-accordian-sub {
        padding: 10px;
        background: rgba(216, 219, 234, 0.15);
        .k-panelbar-item {
            border-radius: 6px;
            border: 1px solid #e6e9f6;
        }
        .k-panelbar-header {
            .k-link {
                background: #fff !important;
                // height: 50px;
            }
        }

        .custom-accordian-sub.disabled {
            .k-panelbar-header {
                .k-link {
                    background: rgba(245, 245, 245, 0.85) !important;
                    pointer-events: none;
                }
            }
            .k-icon {
                display: none;
            }
        }

        .custom-accordian-sub-details {
            // align-items: center;
            display: grid;
            grid-template-columns: auto 663px;
            padding: 0px 37px 20px 66px;
            padding-right: 8px;
            gap: 10px;
            .grid-items{
                display: grid;
                grid-template-columns: 171px 141px 125px 152px;
                gap: 12px;
            }
            .label {
                font-size: 12px;
                line-height: 11px;
                color: #82869b;
            }
            .box {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                gap: 12px;
            }
        }
    }

    .k-checkbox {
        margin-right: 14px;
    }
}

.copyLinkIcon {
    background-image: url('../images/copyIcon-blue.svg') !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    width: 16px;
    height: 16px;
    display: inline-block;
   cursor: pointer;
   z-index: 2;
  }


.custom-accordian-sub .k-icon{
    right: 0 !important;
}

.custom-accordian-sub .accordian-box{
    align-items: center;
    display: grid;
    grid-template-columns: auto 390px;
    padding-top: 7px;
}


// .main-custom-accordian .parent-custom-accordian-sub .custom-accordian-sub-details .grid-items.padd-left{
//     padding-left: 18px;
// }