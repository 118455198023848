.phoenix-form .form-group .custom-input_new{
    padding: 15px 27px 0px 14px !important;
}
.rotate-90 {
    transform: rotate(115deg);
  }

  .fontsize-12 input, .fontsize-12 span.k-input-value-text {
    max-width: calc(100% - 13px);
    font-size: 12px;
  }
  
  .count-0{
    opacity: 0.5;
  }
  .relative-icon-minus {
    position: relative;
    top: 3px;
}
  .div-count{
    position: relative;
  }

  .aligns-count{
    position: absolute;
    top: 3px;
  }
  .cursor{
    cursor: pointer;
  }

  .scenarioBuilder{
    top:-2px
  }
  .assignDash{
    top:6px
  }
  .unassignDash{
    top:4px
  }
  .legList{
    top:2px
  }
  .redColor{
    color: red;
  }