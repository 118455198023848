@import "../css/Variables/Variables.scss";

.user-details-card {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(127, 135, 146, 0.15);
    border-radius: 8px !important;
    padding: 20px;
    border: none;
    margin: 10px;
    display: inline-block !important;
    width: calc(100% / 3 - 20px);
    vertical-align: top;
    height: 180px;
    &:hover {
        background: $white90;
    }
    .team-tile-sec {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        h4 {
            font-size: 28px;
            line-height: 28px;
            font-weight: 300;
            color: #2D3F59;
            margin: 0;
            display: flex;
            align-items: center;
            // max-width: calc(100% - 66px);
            max-width: calc(100% - 88px);
            strong {
                font-weight: 300;
                font-size: 28px;
                line-height: 28px;
                color: $light-grey;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
            .badge {
                background-color: $primary-dark-blue !important;
                font-weight: 600;
                font-size: 13px;
                padding-top: 5px;
                color: #fff;
                margin-left: 12px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 22px;
            }
        }
        .card-action {
            display: flex;
            gap: 8px;
            align-items: center;
            .edit-detils {
                background-image: url("/assets/images/pencil-blue.svg");
                background-repeat: no-repeat;
                background-position: center left;
                padding-left: 20px;
                background-size: 13px;
            }
        }
    }
    .role-trash-button{
        font-size: 16px;
    }
    .about-user {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $dark-grey;
        min-height: 44px;
        div {
            display: inline;
            span {
                cursor: pointer;
            }
        }

        .description{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .created-modified-date {
        border-top: 1px solid rgba(93, 122, 141, 0.3);
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3px;
        label {
            margin: 0;
            background-image: url("/assets/images/calendar-blank.svg");
            background-repeat: no-repeat;
            background-position: top left;
            padding-left: 20px;
            background-size: 13px;
            font-size: 12px;
            color: #242424;
            font-weight: 500;
        }
    }
}

.show-mob {
    display: none !important;
}
@media screen and (max-width: 767px) {
    .show-mob {
        display: flex !important;
    }
    .hide-mob {
        display: none !important;
    }
    .user-details-cards-group {
        margin: 10px 0;
        .user-details-card {
            width: 100%;
            min-height: inherit;
            margin: 10px 0;
            .team-tile-sec {
                padding-bottom: 15px;
                h4 strong {
                    font-size: 24px;
                }
            }
            .about-user {
                display: none;
            }
            .created-modified-date {
                margin-top: 0;
                label {
                    span {
                        display: none;
                    }
                }
            }
        }
        .create-new-team {
            .create-team {
                .btn-link {
                    font-size: 16px;
                    background-position: center left;
                    background-size: 22px;
                    padding-top: 0;
                    padding-left: 30px;
                }
            }
        }
    }
    .hide-mob {
        display: none !important;
    }
    .show-mob {
        display: flex !important;
    }

    // All UserData Table
    // .k-grid.all-user-data-table {
    //     table {
    //         min-width: 100% !important;
    //         col:nth-child(3),
    //         th:nth-child(3),
    //         td:nth-child(3),
    //         col:nth-child(5),
    //         th:nth-child(5),
    //         td:nth-child(5),
    //         col:nth-child(6),
    //         th:nth-child(6),
    //         td:nth-child(6),
    //         col:nth-child(7),
    //         th:nth-child(7),
    //         td:nth-child(7) {
    //             display: none !important;
    //         }

    //         colgroup {
    //             col:nth-child(1) {
    //                 width: 35px !important;
    //             }
    //             col:nth-child(2) {
    //                 width: 40% !important;
    //             }
    //             col:nth-child(4) {
    //                 width: 60% !important;
    //             }
    //             col:last-child {
    //                 width: 70px !important;
    //             }
    //         }
    //         .table-details-data {
    //             .status-col-sec {
    //                 display: flex;
    //                 align-items: flex-start;
    //                 .status-icon {
    //                     width: 8px;
    //                     height: 8px;
    //                     border-radius: 100%;
    //                     display: inline-block;
    //                     margin-top: 4px;
    //                     margin-right: 8px;
    //                 }
    //             }
    //         }
    //     }
    // }
}
@media screen and (min-width: 650px) and (max-width: 991px) {
    .user-details-cards-group {
        margin: 10px -10px;
        padding: 0 5px;
        .user-details-card {
            min-height: inherit;
            margin: 8px;
            width: calc(50% - 16px);
        }
        .create-new-team {
            .create-team {
                min-height: 75px;
            }
        }
    }
    .hide-mob {
        display: none !important;
    }
    .show-mob {
        display: flex !important;
    }
}
.create-new-team {
    border: 1px dashed $dark-grey;
    .create-team {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        min-height: 137px;
        .btn-link {
            flex-direction: column;
            font-size: 14px;
            line-height: 20px;
            padding-top: 35px;
            background-image: url("/assets/images/pluse-circle-blue.svg");
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 20px;
            &:hover,
            &:focus {
                text-decoration: none;
                color: $link-hover;
            }
            svg {
                font-size: 26px;
                margin: 0 0 20px 0;
            }
        }
    }
}
@media screen and (max-width: 650px) {
    .create-new-team {
        .create-team {
            min-height: 40px;
        }
    }
}
@media screen and (min-width: 650px) and (max-width: 991px) {
    .user-details-cards-group {
        margin: 10px -10px;
        padding: 0 5px;
        .user-details-card {
            min-height: inherit;
            margin: 8px;
            width: calc(50% - 16px);
        }
        .create-new-team {
            .create-team {
                min-height: 75px;
            }
        }
    }
    .hide-mob {
        display: none !important;
    }
    .show-mob {
        display: flex !important;
    }
}

.user-details-cards-group {
    margin: 0 -10px;
}

@media screen and (max-width: 767px) {
    .mob-width-full{
        width: 100%;
    }
    .active-log-table .k-master-row td:first-child {
        padding-left: 8px;
    }
    .add-contact-details-model .k-dialog .model-footer-action{
        padding: 15px;
    }
    .add-contact-details-model .k-dialog {
        padding-bottom: 72px;
    }
    .user-details-cards-group {
        margin: 10px 0;
        .user-details-card {
            width: 100%;
            min-height: inherit;
            margin: 10px 0;
            .team-tile-sec {
                padding-bottom: 15px;
                h4 strong {
                    font-size: 24px;
                }
            }
            .about-user {
                display: none;
            }
            .created-modified-date {
                margin-top: 0;
                label {
                    span {
                        display: none;
                    }
                }
            }
        }
        .create-new-team {
            .create-team {
                .btn-link {
                    font-size: 16px;
                    background-position: center left;
                    background-size: 22px;
                    padding-top: 0;
                    padding-left: 30px;
                }
            }
        }
    }
    .hide-mob {
        display: none !important;
    }
    .show-mob {
        display: flex !important;
    }

    // All UserData Table
    // .k-grid.all-user-data-table {
    //     table {
    //         min-width: 100% !important;
    //         col:nth-child(3),
    //         th:nth-child(3),
    //         td:nth-child(3),
    //         col:nth-child(5),
    //         th:nth-child(5),
    //         td:nth-child(5),
    //         col:nth-child(6),
    //         th:nth-child(6),
    //         td:nth-child(6),
    //         col:nth-child(7),
    //         th:nth-child(7),
    //         td:nth-child(7) {
    //             display: none !important;
    //         }

    //         colgroup {
    //             col:nth-child(1) {
    //                 width: 35px !important;
    //             }
    //             col:nth-child(2) {
    //                 width: 40% !important;
    //             }
    //             col:nth-child(4) {
    //                 width: 60% !important;
    //             }
    //             col:last-child {
    //                 width: 70px !important;
    //             }
    //         }
    //         .table-details-data {
    //             .status-col-sec {
    //                 display: flex;
    //                 align-items: flex-start;
    //                 .status-icon {
    //                     width: 8px;
    //                     height: 8px;
    //                     border-radius: 100%;
    //                     display: inline-block;
    //                     margin-top: 4px;
    //                     margin-right: 8px;
    //                 }
    //             }
    //         }
    //     }
    // }
}
