body {
    padding-top: 70px;
    background: #f3f3f3;
    overflow-x: hidden;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.overflow-auto {
    overflow: auto;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

.bg-preloader {
    position: fixed;
    content: "";
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5019607843);
    left: 0;
    top: 0;
    z-index: 9999999;
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("/assets/images/AirplaneLoadingSymbol_Transparent.svg");
    background-repeat: no-repeat;
    width: 187px;
    z-index: 99999991;
}

.k-loader-canvas {
    display: none;
}

a {
    text-decoration: none !important;
}
h1 {
    font-size: 36px;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 24px;
}
h4 {
    font-size: 20px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.p-all-15 {
    padding: 15px;
}
.fs-32 {
    font-size: 32px;
}

.f-lh-11 {
    line-height: 11px;
}

.f-lh-13 {
    line-height: 13px;
}

.f-lh-14 {
    line-height: 14px;
}

.f-lh-normal {
    line-height: normal;
}

.f-lh-16 {
    line-height: 16px;
}

.f-lh-18 {
    line-height: 18px;
}
.f-lh-20 {
    line-height: 20px;
}

.text-dark-grey {
    color: $dark-grey;
}

.text-blue-grey {
    color: #7d8a9a;
}

.text-dark-blue-search {
    color: #122a46 !important;
}

.purple-grey {
    color: $purple-grey;
}

.primary-light-blue {
    color: $primary-light-blue;
}
.text-dark-black-color {
    color: $text-dark-black-color;
}
.text-blue-darken {
    color: #000c52;
}
.text-blue {
    color: #00106e;
}
.text-dark-blue {
    color: $dark-blue;
}
.text-light-grey {
    color: #6f748e;
}
.text-dark-primary {
    color: #001489;
}

.text-dark-green {
    color: #1c9240;
}

.text-notes-black {
    color: #242424;
}

.w-30-per {
    width: 30%;
}

.w-45-per {
    width: 45%;
}

.height-30 {
    height: 30px;
}

.height-74 {
    height: 74px;
}

.height-150 {
    height: 150px;
}

.font-300 {
    font-weight: 300 !important;
}
.font-400 {
    font-weight: 400 !important;
}
.font-500 {
    font-weight: 500 !important;
}
.font-600 {
    font-weight: 600 !important;
}
.font-700 {
    font-weight: 700 !important;
}

.mr-5 {
    margin-right: 5px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-20 {
    margin-right: 20px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-50 {
    margin-top: 50px;
}
.mb-25 {
    margin-bottom: 25px;
}

.mr-4 {
    margin-right: 4px;
}

.mr-6 {
    margin-right: 6px;
}
.mb-6 {
    margin-bottom: 6px;
}
.mt-25 {
    margin-top: 25px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mb-20 {
    margin-bottom: 20px !important;
}

.mr-25-per {
    margin-right: 25%;
}

.pl-100px {
    padding-left: 100px;
}

.mt-1-minus {
    margin-top: -1px;
}

.mt-30 {
    margin-top: 30px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.ml-20 {
    margin-left: 20px;
}
.ml-30 {
    margin-left: 30px;
}
.mr-10 {
    margin-right: 10px;
}
.parent-input-height-52 .k-multiselect {
    min-height: 52px;
}

.input-height-52 {
    min-height: 52px;
}

.fs-30 {
    font-size: 30px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px !important;
}

.height-44 {
    height: 44px;
}

.w-10px {
    width: 10px !important;
}

.w-30px {
    width: 30px !important;
}

.w-35px {
    width: 35px !important;
}

.w-40px {
    width: 40px !important;
}

.w-45px {
    width: 45px !important;
}

.w-50px {
    width: 50px !important;
}

.w-55px {
    width: 55px !important;
}

.w-60px {
    width: 60px !important;
}

.w-65px {
    width: 65px !important;
}

.w-70px {
    width: 70px !important;
}

.w-75px {
    width: 75px !important;
}

.w-80px {
    width: 80px !important;
}

.w-85px {
    width: 85px !important;
}

.w-90px {
    width: 90px !important;
}

.w-95px {
    width: 95px !important;
}

.w-100px {
    width: 100px !important;
}

.w-122px {
    width: 122px;
}

.w-125px {
    width: 125px !important;
}

.w-150px {
    width: 150px !important;
}

.w-175px {
    width: 175px !important;
}

.w-200px {
    width: 200px !important;
}

.w-225px {
    width: 225px !important;
}

.w-250px {
    width: 250px !important;
}

.w-275px {
    width: 275px !important;
}

.w-300px {
    width: 300px !important;
}

.w-325px {
    width: 325px !important;
}

.w-350px {
    width: 350px !important;
}

.w-375px {
    width: 375px !important;
}

.w-400px {
    width: 400px !important;
}

.w-425px {
    width: 425px !important;
}

.w-450px {
    width: 450px !important;
}

.w-475px {
    width: 475px !important;
}

.w-500px {
    width: 500px !important;
}

.w-550px {
    width: 550px !important;
}

.w-600px {
    width: 600px !important;
}

.w-650px {
    width: 650px !important;
}

.w-750px {
    width: 750px !important;
}

.w-950px {
    width: 950px !important;
}

.mx-w-500px {
    max-width: 500px !important;
}

.k-icon {
    font-family: "WebComponentsIcons";
}
.fontLeagueSpartan {
    font-family: $leagueSpartanFont !important;
}
// .text-danger{
//   color: $error-color;
// }
.modal-open {
    overflow: hidden;
    padding-right: 17px;
}

.phoenix-form-card {
    background: $white-color;
    box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);
    border-radius: 10px;
    padding: 40px 80px;
    padding-left: auto;
    padding-right: auto;

    .phoenix-form {
        .form-info-view {
            padding-top: 30px;
            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: $label-color;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $dark-grey;
            }
        }
    }
}
.dropdown-item {
    cursor: pointer;
}
.pointer-class {
    cursor: pointer;
}
.height-32 {
    height: 32px;
}
.height-36 {
    height: 36px;
}
.height-38 {
    height: 38px;
}
.height-42 {
    height: 42px;
}

.k-button-solid-primary,
.k-button-outline-primary {
    font-weight: 700;
    font-size: 14px;
    //line-height: 16px;
    border: 2px solid $primary-dark-blue;
    padding: 12px;
    border-radius: 4px;
    transition: 300ms ease all;
    // min-height: 40px;
}

.k-button-solid-primary {
    background-color: $primary-dark-blue;
    border-width: 1px;
    &:hover,
    &:focus,
    &:active {
        background-color: $primary-light-blue;
        border-color: $primary-light-blue;
        color: $white-color;
        outline: none;
    }
    &:focus,
    &:active {
        border-color: $white-color;
        box-shadow: 0px 0px 3px 2px rgba(0, 20, 137, 0.7);
    }
}

.k-button-solid-error:focus,
.k-button-solid-error.k-focus {
    box-shadow: 0px 0px 3px 2px rgba(220, 53, 69, 0.7);
    border: 1px solid $white-color;
}

.btn-disabled {
    background: #b4b6c3;
    color: #fff;
    border-color: #b4b6c3;
    font-weight: 700;
    font-size: 14px;
    pointer-events: none;

    &:hover {
        background: transparent;
        color: #b4b6c3;
    }
}

.k-button-outline-primary {
    color: $primary-dark-blue;
    &:hover,
    &:focus,
    &:active {
        background-color: #dde1f6;
        border-color: $primary-dark-blue;
        color: $primary-dark-blue;
        outline: none;
    }
    &:focus,
    &:active {
        box-shadow: 0px 0px 3px 2px rgba(0, 20, 137, 0.7);
    }
}

.k-button-flat-primary {
    color: $primary-dark-blue;
    border: 2px solid transparent;
    &:focus,
    &:hover {
        border: 2px solid $primary-dark-blue;
        box-shadow: none;
        outline: 2px solid $primary-dark-blue;
        color: $link-hover;

        &::after,
        &::before {
            display: none !important;
        }
    }
}

.btn-primary {
    color: #fff;
    background: $primary-dark-blue;
    border: 2px solid $primary-dark-blue;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 20px;
    &:focus,
    &:hover {
        border: 2px solid $primary-dark-blue;
        color: $link-hover;
        background: transparent;

        &::after,
        &::before {
            display: none !important;
        }
    }
}

.btn-outline-primary {
    border: 2px solid $primary-dark-blue;
    border-radius: 4px;
    color: $primary-dark-blue;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 20px;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: $primary-dark-blue;
        border: 2px solid $primary-dark-blue;
        color: $white-color;
        box-shadow: none;
        outline: none;
    }
}

.btn-outline-secondary {
    border: 2px solid #919aa9;
    border-radius: 4px;
    color: $grey-secondary-button-color;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    padding: 4px 20px;

    &:hover,
    &:focus,
    &:active {
        background-color: $grey-secondary-button-color;
        border: 2px solid $grey-secondary-button-color;
        color: $white-color;
        box-shadow: none;
        outline: none;
    }
}

.text-primary {
    color: $primary-dark-blue !important;
}

.blue-bubble{
    width: 38px !important;
    height: 38px !important;
    font-size: 28px;
    padding-top: 5px;
}
.blue-bubble-client-profile{
    width: 17px !important;
    height: 17px !important;
    margin-bottom: 2px;
    font-size: 12px;
    padding-top: 2px;
}
.blue-bubble-dashboard{
    width: 15px !important;
    height: 15px !important;
    margin-bottom: 2.5px;
    font-size: 10px;
    padding-top: 2px;
}
.bubble-style{
    background-color: #AEB2E0;
    border: 1px solid #6D29B8;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #3E4BB9;
    display: flex;
    border-radius: 50%;
}
.btn-secondary {
    color: $grey-secondary-button-color;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

// .whiteEditIconBtn {
//   background-image: url('/assets/images/pencil-white.svg');
// }

// .whiteAddIconBtn {
//   background-image: url('/assets/images/pluse-white.svg');
// }

.white-edit-icon-btn,
.white-add-icon-btn {
    background-repeat: no-repeat;
    background-position: center left 15px;
    background-size: 14px;
    padding-left: 38px;
}

.edit-icon-btn {
    background: url("/assets/images/pencil-blue.svg") !important;
}

// .editIconBtn.k-disabled {
//   background: url('/assets/images/pencil-grey.svg') !important; opacity: 1;
// }
// .deleteIconBtn{
//   background: url('/assets/images/delete-blue.svg') !important;
// }
// .deleteIconBtn.k-disabled{
//   background: url('/assets/images/delete-grey.svg') !important; opacity: 1;
// }
// .clockRotateIconBtn{
//   background: url('/assets/images/clock-rotate-icon.svg') !important;
// }
.edit-icon-btn,
.edit-icon-btn.k-disabled,
.delete-icon-btn,
.delete-icon-btn.k-disabled,
.clock-rotate-icon-btn {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 0;
}
.k-button-overlay,
.k-button::before {
    background: inherit;
}
.more-icon-dropdown-btn {
    //display: flex;
    .k-button {
        // background: url('/assets/images/moreDots.svg') !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 24px !important;
        width: 32px;
        height: 32px;
        display: inline-block;
        font-size: 0;
    }
}

.btn-link {
    color: $primary-dark-blue;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    display: flex;
    align-items: center;

    &:focus,
    &:hover,
    &:active {
        outline: none;
        box-shadow: none;
        text-decoration: none;
        color: $link-hover;
    }
}

.add-btn-link {
    // background-image: url('/assets/images/pluse-circle-blue.svg');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 18px;
    padding-left: 25px;
}

.delete-btn-link {
    // background-image: url('/assets/images/delete-red.svg');
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    padding-left: 24px;
    background-color: transparent;
    border: none;
    @extend .text-danger;
    &:focus,
    &:hover {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        &::after,
        &::before {
            display: none !important;
        }
    }
}

.dark-purple-btn {
    background: #82869b;
    color: #fff;
    border-color: #82869b;
}

.custom-badge {
    background: #e1e4f3;
    border-radius: 100px;
    font-weight: 600;
    font-size: 10px;
    line-height: 9px;
    color: $primary-dark-blue;
    padding: 5px 10px 4px 10px;
    margin-left: 5px;
}

.loader {
    width: 24px;
    height: 24px;
    border: 2px solid #d9dff6;
    border-bottom-color: $primary-dark-blue;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.top-title-sec {
    padding: 0px 0 15px 0;
    .title-back-btn {
        display: inline-block;
    }
    label {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #7d8a9a;
    }

    h1 {
        font-weight: 300;
        font-size: 32px;
        color: $primary-dark-blue;
        margin-bottom: 0;
    }

    .row {
        align-items: center;
    }

    .title-back-btn {
        .back-btn {
            color: $dark-grey;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            //display: flex;
            display: inline-block;
            align-items: center;
            margin-bottom: 5px;
            padding-left: 16px;
            position: relative;
            cursor: pointer;
            span {
                // padding-left: 5px;
                padding-left: 2px;
            }
            &::before {
                content: "";
                position: absolute;
                background-image: url("/assets/images/right-angle-grey.svg");
                background-repeat: no-repeat;
                background-size: 7px;
                width: 20px;
                height: 16px;
                left: 0;
                background-position: center left;
            }
        }

        h1 {
            font-weight: 300;
            font-size: 32px;
            color: $primary-dark-blue;
            margin-bottom: 0;
        }
    }

    .right-btn-group {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        // .k-button-outline-primary {
        //   margin-right: 15px;
        // }
    }
}

.form-wrapper {
    max-width: 914px;
    margin: 0 auto;
}

.pin-note {
    display: flex;
    align-items: center;
    height: 25px;

    p {
        font-weight: 400;
        font-size: 16px;
        color: #00041b;
        margin-top: 18px;
        margin-left: 13px;
    }

    .pin-note-icon {
        width: 25px;
        height: 100%;
        cursor: pointer;
        background: #ffffff;
        border: 1.2px solid #b4b6c3;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.phoenix-form {
    max-width: 678px;
    margin-left: auto;
    margin-right: auto;

    .form-title {
        font-weight: 300;
        font-size: 24px;
        line-height: 22px;
        color: $dark-grey;
    }

    .form-group {
        margin-bottom: 0;

        .private-note-text {
            font-weight: 400;
            font-size: 16px;
            color: #00041b;
            margin-left: 10px;
        }

        .k-floating-label-container {
            width: 100%;
            padding: 0;
        }

        .k-text-disabled {
            cursor: not-allowed;

            .custom-input,
            .k-input {
                background: rgba(242, 244, 246, 0.8);
            }
        }

        .k-floating-label-container.k-empty > .k-label {
            color: $label-color;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            position: absolute;
            pointer-events: none;
            left: 14px !important;
            right: 14px;
            top: 16px;
            transition: 300ms ease all;
            margin: 0;
            // letter-spacing: 0.4px;
            width: calc(100% - 28px);
            display: flex;
            align-items: center;
            max-width: inherit;
        }

        .k-floating-label-container > .k-label,
        .k-floating-label-container.k-focus > .k-label {
            left: 14px;
            top: 6px;
            font-size: 12px;
            color: #6f748e;
            display: flex;
            align-items: center;
            right: 14px;
            z-index: 1;
            max-width: inherit;
            font-weight: 400;
        }
        .k-floating-label-container .k-textarea:valid ~ .k-label {
            top: 30px;
        }

        .k-floating-label-container .k-multiselect.k-empty ~ .k-label {
            color: $label-color;
            font-size: 16px;
            top: 38px;
        }

        .k-floating-label-container > .k-multiselect:has(.k-chip-list:empty) ~ .k-label {
            // background-color: $deep-blue !important;
            top: 16px !important;
            z-index: 0;
            font-size: 16px;
            line-height: 15px;
        }

        .custom-input,
        .k-input {
            background: transparent;
            border-radius: 4px;
            color: #00041b;
            padding: 16px 14px 0px 14px;
            font-weight: 400;
            font-size: 16px;
            z-index: 2;
            height: 44px;
            font-family: $leagueSpartanFont;
            .k-input-value-text {
                max-width: calc(100% - 15px);
            }
            &:focus,
            &:focus-within {
                box-shadow: none;
                border-color: $primary-light-blue;
            }
        }
        .k-multiselect {
            min-height: 44px;
            height: auto;
            border-color: #cdd2e1;
        }
        .k-dateinput {
            padding: 0;
            height: auto;
        }
        .custom-input.k-invalid-error,
        .k-input.k-invalid-error {
            background: #fff7f7;
            // border-color: $error-color;
        }

        .optional-field + label,
        .optional-field + .k-label {
            &:after {
                content: "Optional";
                font-weight: 400;
                font-size: 10px;
                line-height: 9px;
                color: rgba(111, 116, 142, 0.8);
                position: absolute;
                right: 0;
            }
        }
        // .k-dropdown-list.optional-field + label,
        // .k-dropdown-list.optional-field + .k-label,
        // .k-datepicker.optional-field + .k-label,
        // .k-floating-label-container input[type="number"] + .k-label {
        //     &:after {
        //         content: "(optional)";
        //         position: initial;
        //         margin-left: 5px;
        //     }
        // }

        .k-floating-label-container input[type="number"]:after,
        .k-floating-label-container input[type="number"]:before {
            position: absolute;
            right: 5px;
            top: 20px;
        }

        .custom-input.k-dropdownlist + .k-label,
        .k-input.k-dropdownlist + .k-label {
            max-width: calc(100% - 50px);
        }
        .k-input-md .k-input-inner,
        .k-picker-md .k-input-inner {
            padding: 0;
        }
        .k-button,
        .k-clear-value {
            background-image: url("/assets/images/down-angle-grey.svg");
            background-repeat: no-repeat;
            transform: rotate(360deg);
            background-position: center;
            background-size: 14px;
            position: absolute;
            right: 6px;
            top: 6px;
            height: 30px;
            width: 30px;
            background-color: inherit;
            z-index: 9;
            border: none;

            .k-i-caret-alt-down,
            .k-icon {
                display: none;
            }
        }
        .k-clear-value {
            background-image: url("/assets/images//icon-close-blue.svg");
            opacity: 1;
            background-size: 10px;
        }
        span[aria-expanded="true"] .k-button {
            transform: rotate(180deg);
        }
        .k-focus .k-clear-value {
            transform: rotate(180deg);
        }

        .k-textarea.custom-input.custom-sign {
            textarea.k-input-inner {
                min-height: 90px;
                margin: 0px -14px -4px -14px;
                padding: 3px 10px 10px 14px;
                border-radius: 4px;
                -ms-overflow-style: thin;
                scrollbar-width: thin;
                // font-family: $AlluraFont;
                font-size: 26px;
            }
        }

        .k-textarea.custom-input {
            height: auto;

            textarea.k-input-inner {
                min-height: 90px;
                padding: 5px 0px 10px 0px;
                border-radius: 4px;
                -ms-overflow-style: thin;
                scrollbar-width: thin;
                font-family: $leagueSpartanFont;
                scrollbar-color: #cdcdcd #fff;
                font-size: 16px;
            }
            textarea.k-input-inner::-webkit-scrollbar {
                width: 6px;
            }
            textarea.k-input-inner::-webkit-scrollbar-thumb {
                background-color: #cdcdcd;
                border-radius: 20px;
            }

            textarea#note-id {
                min-height: 90px;
                margin: 0px -14px -4px -14px;
                padding: 3px 10px 10px 14px;
                border-radius: 4px;
                -ms-overflow-style: thin;
                scrollbar-width: thin;
                scrollbar-color: #cdcdcd #fff;
                font-family: "League Spartan";
                font-size: 16px;
            }
            textarea#note-id::-webkit-scrollbar {
                width: 6px;
            }
            textarea#note-id::-webkit-scrollbar-thumb {
                background-color: #cdcdcd;
                border-radius: 20px;
            }
        }
        .k-textarea.custom-input ~ .k-label {
            background: $white-color;
            z-index: 9;
            //width: 100%;
        }

        .k-floating-label-container.k-empty {
            .k-textarea.custom-input {
                textarea.k-input-inner {
                    margin-top: -18px;
                    padding-top: 18px;
                    min-height: 90px;
                    resize: none;
                }
            }
        }
        .text-danger {
            padding-top: 5px;
            display: block;
            font-weight: 400;
            font-size: 12px;
        }

        .date-picker.custom-input .k-button,
        .time-picker.custom-input .k-button {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 14px;
            position: absolute;
            right: 8px;
            top: 6px;
            height: 30px;
            width: 30px;
            background-color: transparent;
            border: none;
            z-index: 99;
            padding: 0;

            .k-icon:before {
                display: none;
            }
        }
        .k-datepicker .k-button {
            background-image: url("/assets/images/calender-grey.svg");
            transform: none;
        }
        // .time-picker .k-button {
        //     background-image: url('/assets/images/clock-grey.svg');
        //     transform: none;
        // }
    }
    .loaded-active {
        position: relative;

        .loader {
            position: absolute;
            right: 15px;
            top: 38px;
        }
    }
    .k-dateinput {
        .k-input-inner {
            font-family: $leagueSpartanFont;
            font-weight: 400;
            font-size: 16px;
            color: $dark-grey;
            // text-transform: uppercase;
        }
    }

    .k-multiselect[aria-expanded="true"] {
        background-image: url("/assets/images/up-angle-grey.svg") !important;
    }
    // Multiselect box
    .k-multiselect {
        background-image: url("/assets/images/down-angle-grey.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center right 15px !important;
        background-size: 14px !important;

        .k-selection-multiple {
            padding: 5px 0 5px 0;
            padding-right: 38px;

            .k-chip-solid-base {
                border: none;
                color: $deep-blue;
                background-color: #e6e9f7;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                border-radius: 100px;
                letter-spacing: 0;
                padding: 7px 9px;
                padding-right: 12px;

                .k-chip-content {
                    padding: 0;
                    margin-left: 0;
                }

                .k-chip-actions {
                    margin: 0;

                    .k-chip-remove-action {
                        .k-i-x-circle {
                            // background-image: url('/assets/images/close-blue.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 100%;
                            height: 6.3px;
                            width: 6.3px;
                            margin-left: 0;
                            top: 6px;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .k-clear-value {
            display: none;
        }
    }

    .row-action-btn {
        position: absolute;
        left: calc(100% + 10px);
        top: 25px;
        display: flex;
        align-items: center;
        height: 52px;
        padding: 0;
        width: auto;

        .permission-btn {
            // background-image: url('/assets/images/permission-blue.svg');
        }

        .delete-btn {
            // background-image: url('/assets/images/delete-blue.svg');
        }

        .icon-btn {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            font-size: 0px;
            width: 30px;
            height: 30px;
            padding: 0;

            &:focus,
            &:hover,
            &:active {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .add-role-btn {
        display: flex;
        margin-top: 10px;
        margin-bottom: -5px;
        justify-content: flex-end;

        .btn-link {
            // background-image: url('/assets/images/pluse-circle-blue.svg');
            background-repeat: no-repeat;
            background-position: left;
            // background-size: 18px;
            // padding-left: 25px;
            // padding-top: 5px;
            background-size: 16px;
            padding-left: 20px;
            padding-top: 3px;
        }
    }

    .remove-team-btn {
        margin-bottom: -5px;
        display: flex;
        margin-top: 10px;
        justify-content: flex-end;

        .btn-link {
            // background-image: url('/assets/images/delete-blue.svg');
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 14px;
            padding-left: 22px;
        }
    }

    .add-member-group {
        border-bottom: 1px solid #e6e9f7;
        padding-bottom: 25px;

        .action-btn-space {
            position: relative;
        }
    }

    .form-bottom-btns {
        padding-top: 25px;
        display: flex;
        justify-content: flex-end;

        .k-button-outline-primary {
            display: flex;
            align-items: center;
            padding-top: 8px;
            padding-bottom: 8px;
            // &:hover, &:focus{
            //   .k-button-text{
            //   background-image: url('/assets/images/pluse-circle-white.svg');
            //   }
            // }
            .k-button-text {
                display: flex;
                align-items: center;
                // background-image: url('/assets/images/pluse-circle-blue.svg');
                background-repeat: no-repeat;
                // background-position: center left;
                background-position: top 1px left;
                background-size: 18px;
                padding-left: 25px;
            }

            svg {
                margin-right: 7px;
                font-size: 18px;
            }
        }
    }
}

.checkbox-list {
    span {
        margin-right: 20px;

        .k-checkbox-label {
            margin-left: 10px;
        }
    }
}

.k-checkbox {
    border: 2px solid $primary-light-blue;
    border-radius: 3px;
    width: 16px;
    height: 16px;

    &:checked {
        background-color: $primary-light-blue;
        box-shadow: none;
        border-color: $primary-light-blue;

        &:focus {
            border-color: $primary-light-blue;
            box-shadow: none;
        }
    }

    &:focus {
        border-color: $primary-light-blue;
        box-shadow: none;
    }
    &:checked {
        &:disabled {
            background-color: $light-grey;
            box-shadow: none;
            border-color: $light-grey;
        }
    }
    &:disabled {
        box-shadow: none;
        border-color: $light-grey;
    }
}

.k-radio {
    width: 16px;
    height: 16px;
    background: $white-color;
    border: 2px solid $primary-light-blue;

    &:focus {
        border-color: $primary-light-blue;
        box-shadow: none;
    }

    &:checked {
        border-color: $primary-light-blue;
        background-color: $white-color;
        // background-image: url('/assets/images/blue-circle.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;

        &:focus {
            border-color: $primary-light-blue;
            box-shadow: none;
        }
    }
}

.k-radio + .k-radio-label {
    font-weight: 400;
    font-size: 16px;
    color: $dark-grey;
    margin-left: 5px;
}

.data-list-view-wrapper {
    //Filter css
    .data-grid-filter-sec {
        background: $white-color;
        box-shadow: 0px 4px 30px rgba(211, 211, 211, 0.25);
        border-radius: 5px;
        padding: 14px 20px 8px;
        margin: 0px 0 10px 0;
        position: relative;
        z-index: 9;
        transition: 300ms ease all;
        min-height: 62px;

        .filtered-btn-data {
            display: flex;

            .filter-left-sec {
                .filters-btn {
                    background: url("/assets/images/filter-icon.svg") center left no-repeat !important;
                    background-size: 21px !important;
                    color: $primary-dark-blue;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 13px;
                    padding-left: 26px;

                    .k-button-text {
                        top: 1px;
                        position: relative;
                    }

                    &:hover,
                    &:focus {
                        &:after,
                        &:before {
                            display: none !important;
                        }
                    }

                    .k-button-text {
                        img {
                            max-width: 9px;
                            margin-left: 5px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }

            .quick-filter-sec {
                overflow: hidden;
                h4 {
                    font-size: 14px;
                    margin-bottom: 0;
                    line-height: 20px;
                    top: 1px;
                    position: relative;
                }

                .quick-filter-box {
                    display: inline-flex;
                    align-items: center;
                    background: #fff;
                    border: 1px solid #d0d4ec;
                    color: #000c52;
                    border-radius: 40px;
                    padding: 8px 12px 5px;
                    font-size: 12px;
                    opacity: 0.85;
                    line-height: 20px;
                    gap: 4px;
                    white-space: nowrap;
                    height: 32px;

                    &:last-child {
                        margin-right: 0px !important;
                    }

                    p {
                        font-weight: 600;
                        margin-bottom: 0;
                    }

                    img {
                        cursor: pointer;
                        position: relative;
                        top: -1px;
                    }
                }

                .quick-filter-box.close {
                    background: #e6e9f6;
                    color: #000c52;
                    opacity: 1;
                    margin-right: 10px;
                    // margin-top: 2px;
                    margin-bottom: 6px;
                    // &:last-child {
                    //     margin-right: 0;
                    // }
                }
            }

            .filter-right-btn-sec {
                display: flex;
                gap: 5px;
                margin-left: auto;
                padding-top: 2px;

                .btn-outline-secondary,
                .btn-secondary {
                    font-size: 12px;
                    padding: 4px 15px;
                    min-width: 75px;
                }
            }
            .k-button-flat {
                color: $grey-secondary-button-color;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
            }
        }
        .filter-selected-data {
            padding-left: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 100%;
            overflow: auto;
            -ms-overflow-style: thin;
            scrollbar-width: thin;
            .selected-filter {
                display: flex;
                align-items: center;
                background: #e6e9f7;
                border-radius: 100px;
                padding: 6px 12px;
                gap: 4px;
                color: $deep-blue;
                font-size: 12px;
                line-height: 20px;
                white-space: nowrap;

                span {
                    font-weight: 400;
                }

                strong {
                    font-weight: 600;
                }

                .delete-filter-data {
                    background: url("/assets/images/close-blue.svg") center no-repeat !important;
                    background-size: cover !important;
                    padding: 0;
                    width: 11px;
                    height: 11px;
                    display: inline-block;
                    margin-left: 5px;

                    &:hover,
                    &:focus {
                        &:after,
                        &:before {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

    .k-button-outline-primary {
        padding: 8px 20px;
    }

    .phoenix-form {
        max-width: 100%;
        // margin-top: -10px;
        // padding-bottom: 5px;
    }
}

//Table css
.k-grid {
    border: none;
    background-color: transparent;
    .k-table-thead,
    .k-table-header,
    .k-table-group-sticky-header {
        border-color: transparent;
        background-color: transparent;
    }
    .k-grid-content {
        background-color: transparent;
        // overflow-y: hidden;
        .k-loading-mask {
            .k-loading-image::before {
                width: 0.8em;
                height: 0.8em;
                margin-top: -0.38em;
            }
        }

        .table-details-data {
            padding: 20px 20px 20px 8px;
        }
        .k-detail-row {
            background: rgba(216, 219, 234, 0.15);
            box-shadow: 0px 4px 10px rgba(169, 170, 181, 0.25);
            border-radius: 5px;
        }
        .k-master-row {
            .k-hierarchy-cell {
                padding-left: 0 !important;
                padding-right: 0;
                &:empty {
                    display: none;
                }
                .k-i-plus {
                    // background-image: url('/assets/images/down-angle-blue.svg');
                }
                .k-i-minus {
                    // background-image: url('/assets/images/up-angle-blue.svg');
                }
                .k-icon {
                    background-repeat: no-repeat;
                    background-position: center;
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    table {
        border-spacing: 0 0.6em;
        min-width: 1200px;
        width: 100% !important;
        background-color: transparent;
    }
    colgroup col.k-sorted {
        background-color: transparent;
    }
}

.contact-list-tbl {
    table {
        colgroup {
            col:nth-child(2) {
                width: 40px;
            }
            col:nth-child(3) {
                width: 8%;
            }
            col:nth-child(4) {
                width: 14%;
            }
            col:nth-child(5) {
                width: 17%;
            }
            col:nth-child(6) {
                width: 12%;
            }
            col:nth-child(7) {
                width: 14%;
            }
            col:nth-child(8) {
                width: 90px;
            }
            col:nth-child(9) {
                width: 90px;
            }
            col:nth-child(10) {
                width: 100px;
            }
            col:last-child {
                width: 70px;
            }
        }
        tr {
            th:nth-child(1),
            th:nth-child(2),
            th:nth-child(3),
            th:nth-child(6),
            th:nth-child(7),
            th:nth-child(11) {
                .k-link:after {
                    display: none !important;
                }
            }
        }
        .k-master-row td:nth-child(2) {
            padding-left: 8px !important;
        }
    }
}

@media screen and (max-width: 767px) {
    body {
        padding-top: 60px;
    }
    .icreon-sidebar #main {
        padding: 12px;
    }
    .top-title-sec h1 {
        font-size: 18px;
    }
    // All UserData Table
    .k-grid.contact-list-tbl {
        table {
            min-width: 100% !important;
            col:nth-child(2),
            th:nth-child(2),
            .k-master-row td:nth-child(2),
            col:nth-child(3),
            th:nth-child(3),
            .k-master-row td:nth-child(3),
            col:nth-child(6),
            th:nth-child(6),
            .k-master-row td:nth-child(6),
            col:nth-child(7),
            th:nth-child(7),
            .k-master-row td:nth-child(7),
            col:nth-child(8),
            th:nth-child(8),
            .k-master-row td:nth-child(8),
            col:nth-child(9),
            th:nth-child(9),
            .k-master-row td:nth-child(9),
            col:nth-child(10),
            th:nth-child(10),
            .k-master-row td:nth-child(10) {
                display: none !important;
            }
            colgroup {
                col:nth-child(1) {
                    width: 35px !important;
                }
                col:nth-child(4) {
                    width: 35% !important;
                }
                col:nth-child(5) {
                    width: 65% !important;
                }
                col:last-child {
                    width: 60px !important;
                }
            }
        }
    }

    .data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data {
        display: block;
    }
    .data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data .filter-right-btn-sec {
        margin-left: unset;
    }
    .data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data .quick-filter-sec {
        margin-left: 0;
        margin-top: 12px;
        margin-bottom: 12px;
        flex-wrap: wrap;
        gap: 6px;
    }
}

.k-checkbox {
    border: 2px solid $primary-light-blue;
    border-radius: 3px;
    width: 16px;
    height: 16px;

    &:checked {
        background-color: $primary-light-blue;
        box-shadow: none;
    }
}

.k-grid-header {
    background: #f3f3f3;
    border: none;
    margin-bottom: -8px;
    padding-right: 0;

    .k-grid-header-wrap {
        border: none;
    }

    .k-header {
        border: none !important;
        padding-top: 0;
        padding-bottom: 0;
        overflow: inherit;

        .text-center .k-checkbox {
            margin-bottom: -8px;
        }

        .k-cell-inner {
            margin-top: 0;
            margin-bottom: 0;
        }

        .k-link {
            padding-top: 0;
            padding-bottom: 0;

            &:after {
                background-image: url("/assets/images/shortIcon.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 5px;
                width: 12px;
                height: 10px;
                content: "";
            }

            .k-column-title {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }

        .k-link.\!k-cursor-default {
            &:after {
                display: none;
            }
        }

        .k-column-title {
            font-size: 11px;
            line-height: 10px;
            color: #919aa9;
            font-weight: 500;
        }

        &:last-child {
            .k-column-title {
                // margin: 0 auto;
                padding-right: 10px;
            }
        }

        &:last-child {
            .k-cell-inner {
                .k-link {
                    // display: flex;
                    // justify-content: flex-end;
                }
            }
        }
    }

    .k-header.k-sorted,
    .k-header.k-sorted[aria-sort="ascending"] {
        .k-link:after {
            background-image: url("/assets/images/shortAscendingIcon.svg");
        }
    }

    .k-header.k-sorted[aria-sort="descending"] {
        .k-link:after {
            background-image: url("/assets/images/shortDescendingIcon.svg");
        }
    }

    th.k-sorted {
        background-color: transparent;

        .k-icon {
            display: none;
        }

        .k-i-sort-asc-small {
            color: $primary-light-blue;
        }
    }
}

.k-master-row {
    //background-color: $white-color;
    td {
        color: $dark-grey;
        font-weight: 400;
        //padding: 12px;
        //padding: 12px 8px;
        padding: 12px 8px 10px 8px;
        font-size: 14px;
        line-height: 13px;
        border-left: none;
        border-right: none;
        border-top: 1px solid $grey-light-border;
        border-bottom: 1px solid $grey-light-border;
        background-color: $white-color;
        min-height: 62px;
        height: 62px;

        &:first-child {
            border-left: 1px solid $grey-light-border;
            border-radius: 5px 0 0 5px;
            padding-left: 15px;
            // text-align: center;
        }

        &:last-child {
            border-right: 1px solid $grey-light-border;
            border-radius: 0 5px 5px 0;
            // text-align: right;

            // .grid-actions {
            //     display: flex;
            //     align-items: center;
            //     justify-content: flex-end;
            // }
        }

        // .edit-icon-btn,
        // .delete-icon-btn,
        // .clock-rotate-icon-btn {
        //     &:focus,
        //     &:hover {
        //         background-color: transparent !important;
        //         border: 1px solid $primary-light-blue !important;
        //     }
        // }

        .status-col-sec {
            display: flex;
            align-items: center;

            .status-icon {
                width: 5px;
                height: 5px;
                border-radius: 100%;
                display: inline-block;
                margin-top: -2px;
            }

            label {
                padding-left: 5px;
            }
        }
        .k-checkbox {
            margin-top: -4px;
        }
    }

    td.nameCol,
    td.link-col {
        color: $primary-light-blue;
        font-weight: 600;
        font-size: 14px;
        line-height: 13px;
        cursor: pointer;
    }

    &:hover {
        td {
            background: #f7f8f9 !important;
        }
    }

    &:last-child td {
        border-bottom-width: 1px !important;
    }
}
.k-grid .k-alt,
.k-grid tbody > tr:not(.k-detail-row):hover,
.k-grid tbody > tr:not(.k-detail-row).k-hover {
    background-color: transparent;
}
.k-grid .k-alt td,
.k-grid tbody > tr:not(.k-detail-row):hover td,
.k-grid tbody > tr:not(.k-detail-row).k-hover td {
    background-color: transparent;
}
.disableRow td {
    background: #f7f8f9 !important;
}
tr.k-alt {
    background-color: transparent;
}

.k-grid-pager {
    border: none;
    background: transparent;
    //padding: 10px 0;
    padding: 5px 0;
    flex-direction: row-reverse;

    .k-pager-info {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #7d8a9a;
        display: inline-block;
        text-align: left;
        &:before {
            content: "Showing";
            padding-right: 4px;
        }
    }

    .k-link,
    .k-button {
        border-radius: 2.66176px;
        border: 1px solid #dfe3e8 !important;
        color: $primary-light-blue;
        font-weight: 700;
        font-size: 10px;
        line-height: 18px;
        background: $white-color !important;
        margin-left: 5px;
        width: 24px;
        height: 24px;
        min-width: inherit;
        padding: 0;

        // padding-top: 2px;
        &:focus {
            box-shadow: none;
        }
    }

    .k-selected {
        background: $white-color !important;
        border-color: #3c4456 !important;
        color: #3c4456 !important;
        &:before,
        &::after {
            display: none !important;
        }
    }

    .k-pager-numbers {
        .k-link {
            padding-top: 7px;
        }
    }

    .k-pager-first,
    .k-pager-last {
        //display: none;
        color: #c4cdd5;
    }

    .k-pager-nav {
        &:focus {
            box-shadow: none;
        }

        .k-i-caret-alt-left:before {
            // background: url('/assets/images/pageArrowLeft.svg');
        }

        .k-i-caret-alt-right:before {
            // background: url('/assets/images/pageArrowRight.svg') center no-repeat;
        }

        .k-i-caret-alt-right:before,
        .k-i-caret-alt-left:before {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 7px;
            content: "";
            width: 12px;
            height: 12px;
        }
    }

    .k-pager-nav.k-disabled {
        background: #919eab !important;
        border-color: #919eab !important;

        &:focus {
            box-shadow: none;
        }
    }
}

// Accordion UI
.permissions-list {
    padding-top: 5px;

    .k-expander {
        border-color: transparent;
        background: #f9fafc;
        border-radius: 4px;
        margin-top: 16px;
        &:first-child {
            margin-top: 0;
        }

        .k-expander-header {
            background: rgba(238, 240, 248, 0.85);
            border-radius: 4px;
            padding: 10px 15px 10px 20px;
            min-height: 64px;

            .k-expander-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $dark-blue;
                text-transform: inherit;

                .permissionsListHead {
                    display: flex;
                    align-items: center;

                    label {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .k-expander-indicator {
                .k-icon {
                    color: $deep-blue;
                }
            }
        }

        .k-checkbox-md {
            width: 16px;
            height: 16px;
            border: 2px solid $primary-light-blue;
            border-radius: 2px;
            margin-top: -3px;
            margin-right: 12px;

            &:checked {
                background-color: $primary-light-blue;
            }
        }
        .k-checkbox.k-indeterminate {
            border-color: $primary-light-blue;
            color: $white-color;
            background-color: $primary-light-blue;
        }
    }
    .k-expander.k-disabled {
        .k-checkbox-md:checked,
        .k-checkbox.k-indeterminate {
            background-color: #b4b6c3;
        }
        .k-checkbox-md {
            border: 2px solid #b4b6c3;
        }
    }

    .k-expander.k-focus {
        box-shadow: none;
    }

    .k-expander-content {
        padding: 20px;

        .permissionsCatSec {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            h4 {
                font-weight: 400;
                font-size: 18px;
                line-height: 17px;
                color: #000000;
            }

            .permission-cat-lists {
                .cat-col {
                    background: $white-color;
                    border: 0.5px solid $grey-light-border;
                    border-radius: 5px;
                    height: 62px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    position: relative;
                    margin: 11px 0;

                    label {
                        color: $primary-light-blue;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        padding-right: 20px;
                    }

                    .cat-action-sec {
                        position: absolute;
                        right: 8px;

                        .more-icon-dropdown-btn {
                            .k-button {
                                &:focus,
                                &:hover {
                                    background-color: transparent !important;
                                    border: none !important;

                                    &::before,
                                    &::after {
                                        display: none !important;
                                    }
                                }
                            }
                        }

                        .actions-list {
                            background: $white-color;
                            border: 1px solid $grey-light-border;
                            box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
                            border-radius: 5px;
                            padding: 20px;
                            display: none;
                        }
                    }
                }
            }
        }
        .permission-sub-level-sec {
            padding-bottom: 30px;
            &:last-child {
                padding-bottom: 5px;
            }
            .sub-level-head {
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                color: $dark-blue;
                .k-checkbox-label {
                    margin-inline-start: 0;
                }
            }
            .select-permission-group {
                padding-left: 15px;
                .row .col-lg-6 {
                    padding-left: 25px;
                }
                .field-label-grey {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 11px;
                    color: $label-color;
                    margin: 30px 0 8px 0;
                }
                .k-checkbox-label {
                    margin-inline-start: 0;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 14px;
                    color: $dark-blue;
                }
                .form-group.d-flex {
                    justify-content: flex-end;
                }
                .custom-input.input-border-none {
                    border: none;
                    padding: 0;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    width: auto;
                    color: $label-color;
                    font-weight: 500;
                    background: transparent;
                    min-width: 120px;
                    .k-input-inner {
                        .k-input-value-text {
                            max-width: 100%;
                            font-size: 14px;
                            line-height: 13px;
                            font-weight: 400;
                            text-align: right;
                        }
                    }
                    .k-button {
                        position: initial;
                        padding: 0;
                        height: 14px;
                        width: 14px;
                        background-size: 10px;
                        background-color: transparent;
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}
//End

//Custom dropdown UI
.k-animation-container {
    .k-popup {
        // max-height: 250px;
        background: $white-color;
        border: 1px solid $grey-light-border;
        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
        border-radius: 5px;
        padding: 0px;

        .k-list-content {
            -ms-overflow-style: thin;
            scrollbar-width: thin;
            scrollbar-color: #d3d5dd #f1f1f1;

            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #d3d5dd;
                border-radius: 20px;
            }
            .k-list-ul {
                margin: 0;

                .k-list-item {
                    padding: 7px 15px;

                    .k-list-item-text {
                        font-weight: 600;
                        font-size: 14px;
                        color: $primary-dark-blue;
                    }

                    &:hover {
                        background-color: #f0f2f9;
                    }
                }

                .k-selected {
                    background-color: #f0f2f9;
                }
            }

            .k-focus.k-list-item {
                box-shadow: none;
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
//End

//Custom dropdown popup UI change
.k-animation-container {
    .permissions-drop-down {
        border: 1px solid $primary-light-blue;
        border-radius: 4px;
        background-color: $white-color;
        box-shadow: none;
        //min-width: 140px;
        .k-list-content {
            .k-list-ul {
                padding: 10px 0;
                .k-list-item {
                    padding: 8px 15px;
                    .k-list-item-text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 15px;
                        color: #6f748e;
                    }
                    &:hover {
                        background-color: transparent;
                        .k-list-item-text {
                            color: $primary-dark-blue;
                        }
                    }
                }
                .k-selected {
                    background-color: transparent;
                }
            }
        }
    }
}

// DropDownButton Popup css
.k-menu-group {
    border-color: $grey-light-border;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 0 !important;

    .k-item {
        .k-link {
            color: $primary-dark-blue;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 16px;

            &:hover {
                background: rgba(238, 240, 248, 0.85);
            }
        }
    }

    .k-focus {
        .k-link {
            box-shadow: none !important;
        }
    }
}

//End

//Custom Tooltip UI
.k-tooltip {
    background: $white-color;
    border: 1px solid $grey-light-border;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    padding: 0;

    .k-tooltip-content {
        font-size: 12px;
        line-height: 11px;
        text-align: center;
        color: $dark-grey;
        z-index: 99;
        position: relative;
        padding: 10px 15px;
    }

    .k-callout {
        color: $grey-light-border;
        border-width: 8px;
        bottom: -16px;
        display: none;
    }
}

//Tooltip End

.category-action-nav {
    display: flex;
    align-items: center;
    color: $primary-light-blue;
    font-weight: 600;

    .k-checkbox-md {
        width: 16px;
        height: 16px;
        border: 2px solid $primary-light-blue;
        border-radius: 2px;
        margin-top: -3px;
        margin-right: 12px;
        &:disabled {
            box-shadow: none;
            border-color: $light-grey;
        }
    }

    .k-checkbox-md:checked {
        background-color: $primary-light-blue;
        &:disabled {
            background-color: $light-grey;
            box-shadow: none;
            border-color: $light-grey;
        }
    }

    .k-group {
        padding: 10px 0;
    }

    .k-link {
        box-shadow: none !important;
        border: none !important;
    }

    .k-selected {
        background-color: #f0f2f9;
    }
}

//Permission css
.user-permissions-list {
    margin-top: 22px;
    border: none;

    .k-panelbar-header {
        .k-link {
            background: rgba(238, 240, 248, 0.85) !important;
            border-radius: 4px;
            padding: 10px 15px;
            min-height: 46px;

            .k-panelbar-item-text {
                width: 100%;

                .panel-title-sec {
                    display: flex;
                    align-items: center;

                    img {
                        width: 16px;
                    }

                    strong {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: $primary-dark-blue;
                        padding-left: 12px;
                    }
                }
            }

            .k-icon {
                color: $deep-blue !important;
            }
        }

        .k-link.k-focus {
            box-shadow: none;
        }
    }

    .permissions-cat-sec {
        border-color: transparent;
        background: #f9fafc;
        border-radius: 4px;
        padding: 20px;

        h4 {
            font-weight: 400;
            font-size: 18px;
            line-height: 17px;
            color: #000000;
        }

        .permission-cat-lists {
            .cat-col {
                background: $white-color;
                border: 0.5px solid $grey-light-border;
                border-radius: 5px;
                height: 62px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                position: relative;
                margin: 11px 0;

                .label {
                    color: $primary-light-blue;
                    font-weight: 600;
                    font-size: 14px;
                    padding-top: 3px;
                    margin-bottom: 0px;
                    margin-left: 5px;
                }

                .cat-action-sec {
                    position: absolute;
                    right: 8px;

                    .more-icon-dropdown-btn {
                        .k-button {
                            &:focus,
                            &:hover {
                                background-color: transparent !important;
                                border: none !important;
                                &::before,
                                &::after {
                                    display: none !important;
                                }
                            }
                        }
                    }

                    .actions-list {
                        background: $white-color;
                        border: 1px solid $grey-light-border;
                        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
                        border-radius: 5px;
                        padding: 20px;
                        display: none;
                    }
                }
            }
        }
    }
}

// Upload File Component css
.profile-pic-upload-outer {
    position: relative;

    .uploaded-profile-img {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99;

        img {
            height: 60px;
            width: 60px;
            position: absolute;
            object-fit: cover;
            display: none;

            &:last-child {
                display: block;
            }
        }

        &:empty {
            // background: url('/assets/images/profile-default-img.svg') !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: 100% !important;
            height: 36px;
            width: 36px;
        }
    }

    .upload-doc-component {
        .k-upload-files {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #f8f9fa;
            z-index: 10;
            padding: 0;
            display: flex;
            align-items: center;

            .k-file {
                padding: 0;
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;

                .k-file-single {
                    padding-right: 20px;

                    .k-progressbar {
                        width: 100%;
                        background: #e8e9ec;
                        height: 6px;
                        bottom: 0;

                        .k-progress {
                            background: linear-gradient(218.8deg, #021bae 3.54%, #031164 66.51%);
                        }
                    }

                    .k-file-group-wrapper {
                        display: none;
                    }

                    .k-file-name-size-wrapper {
                        padding-right: 20px;
                        padding-left: 80px;

                        .k-file-name {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 17px;
                            color: $dark-grey;
                        }

                        .k-file-validation-message {
                            margin-top: 5px;
                        }
                    }

                    .k-upload-status {
                        .k-button-icon {
                            background: #f4d9d9;
                            // color: $error-color;
                            width: 28px;
                            height: 28px;
                            overflow: hidden;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-left: 1px;

                            &:hover {
                                // background: $error-color;
                                color: #f4d9d9;
                            }
                        }
                    }
                }
            }
        }
    }
    .document-pdf-view {
        width: 100%;
        min-width: 1000px;
        height: calc(100vh - 170px);
    }
}

// End

//Custom Tooltip UI
.grids-tooltip {
    .k-tooltip {
        max-width: 300px;

        .k-tooltip-content {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $dark-grey;
            text-align: left;
        }
    }
}

.k-tooltip {
    background: $white-color;
    border: 1px solid $grey-light-border;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    padding: 0;

    .k-tooltip-content {
        font-size: 12px;
        line-height: 11px;
        text-align: center;
        color: $dark-grey;
        z-index: 99;
        position: relative;
        padding: 10px 15px;
        // max-height: 200px;
        // overflow: hidden;
    }

    .k-callout {
        color: $grey-light-border;
    }

    .k-callout-n {
        top: -16px;
    }
}

//Tooltip End

// Alert message Css

.wrapper {
    position: relative;
}

.common-alert-msg {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 20px;
    margin: 5px 0;
    z-index: 999;
    .alert-success {
        background: #d4e6da;
        color: #1c9240;
        // background-image: url('/assets/images/check-circle-green.svg');
    }
    .alert-danger {
        background: #f4d9d9;
        // color: $error-color;
        // background-image: url('/assets/images/close-circle-red.svg');
    }
    .alert-warning {
        background: #ffeed3;
        color: #e28f10;
        // background-image: url('/assets/images/warning-orange.svg');
        background-size: 24px !important;
    }
    .alert-primary {
        background: #e7eafe;
        color: #3248cb;
        // background-image: url('/assets/images/info-cicle-blue.svg');
    }

    .alert {
        box-shadow: 0px 4px 20px rgba(127, 135, 146, 0.15);
        border-radius: 8px;
        border: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        padding: 20px 24px 20px 58px;
        margin-bottom: 0;
        background-repeat: no-repeat;
        background-position: center left 24px;
        background-size: 22px;
        text-align: left;
    }
}
.alerts-sec {
    text-align: center;
    padding: 30px;
    .common-alert-msg {
        position: inherit;
        padding-right: 0;
    }
}

// End

//  Confirmation Msg PopUp

.confirmation-msg-popup {
    .k-dialog {
        width: 100%;
        max-width: 350px;
        border-radius: 0;
        border: none;
        background: $white-color;
        box-shadow: 0px -10px 30px rgba(145, 148, 165, 0.25);
        .k-window-content {
            padding: 30px;
            text-align: center;
            .alertMsg {
                font-weight: 400;
                color: $dark-grey;
                font-size: 20px;
                letter-spacing: 0.5px;
            }
        }
        .model-footer-action {
            display: flex;
            justify-content: center;
            padding-top: 30px;
            gap: 15px;
            .k-button {
                min-width: 70px;
                font-weight: 600;
                padding: 9px;
                font-size: 14px;
                line-height: 16px;
                border-radius: 4px;
            }
        }
    }
}
// Delete dialog
.delete-dialog {
    .k-dialog {
        max-width: 500px;
        border-radius: 5px;
        .k-dialog-titlebar {
            border: none;
            padding: 25px 25px 0 30px;
            .k-dialog-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                // background: url('/assets/images/warning-red-icon.svg');
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 20px;
                padding: 3px 0 0 30px;
            }
            .k-button {
                padding: 0;
                .k-button-icon {
                    // background-image: url('/assets/images/close-black.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 18px;
                    &::before {
                        display: none;
                    }
                }
                &:hover,
                &:focus {
                    background-color: transparent;
                    &::before,
                    &:after {
                        display: none !important;
                    }
                }
            }
        }
        .k-window-content {
            text-align: left;
            .alertMsg {
                font-weight: 400;
                font-size: 24px;
                line-height: 26px;
            }
        }
        .model-footer-action {
            justify-content: flex-end;
            padding-top: 30px;
            gap: 15px;
            .k-button {
                min-width: 144px;
                min-height: 42px;
            }
        }
    }
}

//Switch Button

.k-switch {
    width: 36px;

    .k-switch-track {
        width: 100%;
        height: 15.56px;
        box-shadow: 4px 8px 24px #f2f2f5;
        background: rgba(60, 60, 67, 0.3);
        border: none;
    }

    .k-switch-thumb-wrap {
        .k-switch-thumb {
            width: 20px;
            height: 20px;
            box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
            margin-top: -0.5px;
        }
    }
}

.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 26px) !important;
}

.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(100% - 25px - 3px) !important;
}

.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
    left: calc(14px + 18px) !important;
}

.k-switch-on {
    .k-switch-thumb-wrap {
        left: 26px !important;
    }

    .k-switch-thumb {
        background-color: $primary-light-blue;
    }

    .k-switch-track {
        background: $primary-light-blue;
        opacity: 0.5;
    }
}

.k-switch-on.k-focus .k-switch-track,
.k-switch-on.k-focus .k-switch-track {
    border: none;
    outline: none;
}

.k-notification,
.k-notification-success,
.k-notification-closable {
    display: flex !important;
}

.k-notification-warning {
    border-color: #fff3cd;
    color: #e28f10;
    background-color: #ffeed3;
    border-radius: 8px;
}

//End

.k-editor {
    background: $white-color;
    border: 1px solid #e6e9f7;
    border-radius: 8px;
    overflow: hidden;
    //margin: 20px 0;
    margin-top: 20px;
    .k-toolbar {
        background-color: $white-color;
        border-color: #e6e9f7;
        .k-button-group {
            padding: 0 2px;
            display: flex;
            align-items: center;
            .k-button {
                background-color: transparent;
                border: none;
                padding: 0 5px;
                opacity: 1;
                .k-button-icon {
                    font-size: 24px;
                    color: $label-color;
                }
                // .k-i-bold{
                //   background: url('/assets/images/bold-icon.svg');
                //   background-size: 12px;
                // }
                // .k-i-italic{
                //   background: url('/assets/images/italic-icon.svg');
                //   background-size: 14px;
                // }
                // .k-i-underline{
                //   background: url('/assets/images/undeline-icon.svg');
                //   background-size: 15px;
                // }
                // .k-i-link{
                //   background: url('/assets/images/link-icon.svg');
                //   background-size: 22px;
                // }
                .k-i-bold,
                .k-i-italic,
                .k-i-underline,
                .k-i-link {
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 20px;
                    width: 20px;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .k-dropdownlist {
            margin: 0 8px 0 10px;
            .k-input-inner {
                padding: 5px 10px;
            }
            .k-button {
                .k-icon {
                    // background: url('/assets/images/down-angle-grey.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 11px;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        .k-picker-solid {
            border-color: #e4e7eb;
            color: $label-color;
            background-color: transparent;
            width: 100px;
            font-size: 14px;
            border-radius: 4px;
            .k-input-inner {
                padding-right: 0;
            }
        }
        .k-colorpicker {
            border: none;
            position: relative;
            width: 30px;
            .k-input-inner {
                padding: 0;
                // width: 30px;
                .k-icon-color-preview .k-icon {
                    // background: url('/assets/images/bgcolor-icon.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    height: 22px;
                    width: 22px;

                    &:before {
                        display: none;
                    }
                }
                .k-color-preview-mask {
                    height: 2px;
                    position: absolute;
                    bottom: 2px;
                    width: 17px;
                    &:before {
                        display: none;
                    }
                }
            }
            .k-button {
                position: absolute;
                z-index: 99;
                width: 100%;
                height: 100%;
                .k-icon {
                    display: none;
                }
            }
        }
        .k-picker-solid.k-focus {
            box-shadow: none;
        }
    }
}
//calendar UI
.k-calendar {
    color: $dark-grey;
    background: $white-color;
    border: 1px solid $grey-light-border;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    //width: 350px;
    .k-calendar-header {
        margin-left: 0;
        margin-right: 0;
        padding: 15px 22px 5px 22px;
        .k-calendar-title {
            font-weight: 600;
            padding: 0;
            &:hover,
            &:focus {
                background: transparent;
                &::after,
                &::before {
                    display: none !important;
                }
            }
        }
        .k-calendar-nav {
            .k-button,
            .k-calendar-nav-today {
                color: $primary-dark-blue;
                font-weight: 600;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: $link-hover;
                    &::after,
                    &::before {
                        display: none !important;
                    }
                }
            }
        }
    }
    .k-calendar-weekdays {
        width: 100%;
        padding: 0 10px;
        .k-calendar-thead {
            .k-calendar-th {
                font-weight: 400;
                font-size: 14px;
                color: #b4b6c3;
            }
        }
    }
    .k-calendar-content {
        .k-calendar-table {
            width: 100%;
            padding: 0 10px;
            .k-calendar-tbody {
                .k-calendar-td {
                    .k-link {
                        color: $dark-grey;
                        font-weight: 500;
                        font-size: 15px;
                        &:hover {
                            color: $primary-dark-blue !important;
                            border: 1px solid $primary-dark-blue;
                            box-shadow: none;
                            background-color: transparent;
                        }
                    }
                }
            }
            .k-today {
                .k-link {
                    color: $primary-dark-blue !important;
                    border: 1px solid $primary-dark-blue;
                    box-shadow: none;
                }
            }
            .k-selected {
                .k-link {
                    background-color: $primary-dark-blue;
                    border-color: transparent;
                    font-weight: 500;
                    box-shadow: none;
                    color: $white-color !important;
                }
            }
        }
    }
    .k-calendar-navigation {
        color: $dark-grey;
        background: rgba(238, 240, 248, 0.85);
        ul li {
            font-size: 15px;
            font-weight: 500;
            &:hover {
                color: $primary-dark-blue;
            }
        }
        .k-calendar-navigation-marker {
            color: $primary-dark-blue;
        }
    }
    .k-calendar-view {
        padding: 0px;
        // height: 300px;
    }
}
//calendar UI End

//Tabs UI
.custom-tab {
    .k-tabstrip-items-wrapper {
        border-bottom: 0.5px solid #e6e9f7;
        box-shadow: 0px 4px 4px -2px rgba(92, 102, 118, 0.16);
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 15px;
        list-style: none;

        .k-tabstrip-items {
            gap: 10px;
            .k-item {
                background: none;
                border: none;

                .k-link {
                    color: $label-color;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    background: transparent;
                    padding: 12px 16px;
                    border: none;
                    cursor: pointer;
                    position: relative;

                    &:after {
                        content: "";
                        background: $primary-light-blue;
                        height: 2px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        -webkit-transition: all 250ms ease 0s;
                        transition: all 250ms ease 0s;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    .k-badge {
                        transform: inherit;
                        position: inherit;
                        margin-left: 4px;
                        background-color: $primary-light-blue;
                        font-size: 9px;
                        border-radius: 3px;
                        padding: 1px 0 0 0;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 15px;
                        min-width: 16px;
                        font-weight: 600;
                        z-index: 9;
                    }
                }
            }

            .k-active {
                .k-link {
                    color: $primary-light-blue;
                    font-weight: 700;

                    &:after {
                        content: "";
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .k-content {
        background: transparent;
        border: none;
        padding: 0;
        .k-animation-container {
            width: 100%;
        }
    }
}

//Tabs End

//multiselect tree popup UI
.k-multiselecttree-popup {
    .k-list-filter {
        padding: 14px 14px 6px 14px;
    }
    .k-treeview-item {
        padding: 5px 10px 5px 6px;
    }
    .k-treeview {
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        max-height: 200px;
    }
    .k-treeview::-webkit-scrollbar {
        scrollbar-width: thin;
    }
    .k-treeview-leaf {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #6f748e;
        padding: 0 0 0 3px;
        box-shadow: none;
        &:hover,
        &:focus {
            border: none;
            background: none;
            color: $primary-light-blue;
        }
    }
}

//Loader start
.k-loading-mask {
    z-index: 1050;
}

.whitespace-auto {
    white-space: inherit;
}

// Kendo filter css
.k-filter-group-main {
    .k-filter-toolbar {
        display: none;
    }
    .k-filter-lines {
        .k-filter-toolbar {
            display: block;
        }
    }
}
.k-notification-container {
    margin: 0px;
}

.fixed-full-popup-right {
    .inner-scroll {
        padding-top: 10px;
        padding-bottom: 15px;
        height: calc(100% - 75px) !important;
    }
    .phoenix-form {
        padding-right: 15px;
    }
    .k-dialog {
        position: fixed;
        right: 0;
        width: 100%;
        max-width: 550px;
        top: 0;
        bottom: 0;
        border-radius: 0;
        border: none;
        background: #ffffff;
        box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);

        .modelCustomTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;

            h4 {
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                letter-spacing: 0.2em;
                color: $text-dark-black-color;
                margin: 0;
                text-transform: uppercase;
            }
        }

        .k-window-actions,
        .k-dialog-titlebar-actions {
            margin: 0;

            .k-button {
                opacity: 1;
                padding: 0;

                &:focus,
                &:hover {
                    box-shadow: none !important;
                    background: none !important;

                    &:before,
                    &:after {
                        display: none !important;
                    }
                }

                .k-button-icon {
                    // background: url('/assets/images/close-black.svg') !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 16px !important;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .model-footer-action {
            text-align: right;
            padding: 24px;
            box-shadow: 0px 0px 20px rgba(145, 148, 165, 0.25);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
            background: #fff;
            gap: 10px;
            display: flex;
            justify-content: flex-end;

            .k-button {
                font-weight: 600;
            }
        }

        .k-window-content {
            padding: 0px 9px 24px 24px;
        }

        .k-dialog-titlebar {
            border: none;
            padding: 15px 20px 15px 24px;

            .k-window-title {
                font-weight: 400;
                font-size: 12px;
                line-height: 11px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: $text-dark-black-color;
            }

            .k-button {
                opacity: 1;
                padding: 0;

                .k-button-icon {
                    // background: url('/assets/images/close-black.svg') !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 16px !important;
                    opacity: 1;

                    &::before {
                        display: none;
                    }
                }

                &:hover {
                    background-color: transparent;

                    &::before,
                    &::after {
                        display: none !important;
                    }
                }
            }
        }
    }
}
.pr-0 {
    padding-right: 0 !important;
}

.first-client-popup-scroll {
    padding-top: 10px;
    padding-bottom: 15px;
    height: calc(100% - 75px) !important;
    .phoenix-form {
        padding-right: 15px;
        padding-bottom: 20px;
        margin-top: -20px;
    }
}
.promotion-list-dropdown-item {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: $primary-dark-blue;
    font-family: $leagueSpartanFont;
    // pointer-events: none;
}
.contact-list-delete-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #b4b6c3;
    // pointer-events: none;
}

.contact-list-delete-button:hover {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

.contact-delete-confirmation .k-dialog {
    max-width: 450px !important;
}

.toast-notification {
    .k-icon {
        margin-right: 5px;
        margin-top: 1.1px;
    }

    .k-notification-actions {
        position: relative;
        bottom: 1px;
        left: 5px;
    }
}

#editIconBtnDummy {
    background: none !important;
}
#editIconBtnDummy:hover {
    border: none !important;
}
.editPassenger .k-panelbar-item-text {
    width: 100%;
}

// Collapse | Expand Data Table
@media screen and (min-width: 768px) {
    .k-grid.detail-rows-tbl {
        table {
            .k-hierarchy-cell .k-link:after,
      col:nth-child(1),
      th:nth-child(1),
      td:nth-child(1)/* {display: none;} */
      .k-master-row td:nth-child(2) {
                text-align: left;
                border-left: 1px solid transparent !important;
                border-radius: 5px 0 0 5px;
                padding-left: 15px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .k-grid.detail-rows-tbl {
        table {
            .k-detail-row .k-hierarchy-cell,
            .k-hierarchy-cell .k-link:after {
                display: none;
            }
        }
        .k-detail-cell {
            padding: 0;
            white-space: normal;
            .tableDetailsData {
                padding: 15px 20px 5px 20px;
                label {
                    margin-bottom: 6px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 11px;
                    color: $label-color;
                    display: block;
                    word-wrap: break-word;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: $dark-grey;
                    margin-bottom: 20px;
                    word-wrap: break-word;
                }
                .link-col {
                    color: $primary-light-blue;
                    font-weight: 600;
                }
            }
        }
    }
}
// Collapse | Expand Data Table End

.swc {
    .swc-checkbox {
        input {
            margin-right: 7px;
            position: relative;
            bottom: 2px;
        }
    }
}
@media screen and (max-width: 767px) {
    .k-grid.detail-rows-tbl {
        table {
            .k-detail-row .k-hierarchy-cell,
            .k-hierarchy-cell .k-link:after {
                display: none;
            }
        }
        .k-detail-cell {
            padding: 0;
            white-space: normal;
            .tableDetailsData {
                padding: 15px 20px 5px 20px;
                label {
                    margin-bottom: 6px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 11px;
                    color: $label-color;
                    display: block;
                    word-wrap: break-word;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: $dark-grey;
                    margin-bottom: 20px;
                    word-wrap: break-word;
                }
                .link-col {
                    color: $primary-light-blue;
                    font-weight: 600;
                }
            }
        }
    }
}
.link-col {
    color: $primary-light-blue;
    font-weight: 600;
}
.swc-cross-button {
    right: 32px;
    top: 42px;
    z-index: 100;
    color: #999cad;
    cursor: pointer;
    font-size: 14px;
}

.swc-ms-cross-button {
    position: absolute;
    right: 32px;
    top: 55%;
    z-index: 4;
    color: #999cad;
    font-size: 14px;
    cursor: pointer;
}
.swc-cross-button {
    right: 32px;
    top: 42px;
    z-index: 111;
    color: #999cad;
    cursor: pointer;
    font-size: 14px;
}

.swc-ms-cross-button {
    position: absolute;
    right: 32px;
    top: 55%;
    z-index: 111;
    color: #999cad;
    font-size: 14px;
    cursor: pointer;
}
.swc-cross-button {
    right: 32px;
    top: 42px;
    z-index: 100;
    color: #999cad;
    cursor: pointer;
    font-size: 14px;
}

.swc-ms-cross-button {
    position: absolute;
    right: 32px;
    top: 55%;
    z-index: 4;
    color: #999cad;
    font-size: 14px;
    cursor: pointer;
}
.swc-cross-button {
    right: 32px;
    top: 42px;
    z-index: 111;
    color: #999cad;
    cursor: pointer;
    font-size: 14px;
}

.swc-ms-cross-button {
    position: absolute;
    right: 32px;
    top: 55%;
    z-index: 111;
    color: #999cad;
    font-size: 14px;
    cursor: pointer;
}

.role-trash-button {
    color: #d14141;
    cursor: pointer;
}

.delete-role-dialog {
    .k-dialog {
        // max-width: calc(30%);
    }

    .k-dialog-titlebar {
        padding: 1em !important;
    }

    .k-dialog-content {
        padding: 1em !important;
    }

    .message {
        text-align: start;
    }

    .model-footer-action {
        justify-content: end !important;
        padding-top: 10px !important;
    }

    .delete-role-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        /* identical to box height */

        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* Black */

        color: #00041b;
    }
}

.role-view-label {
    /* Dark Purple Grey */
    font-weight: 400;
    font-size: 12px;
    color: #82869b;
}

.role-view-value {
    /* Black */
    color: #00041b;
    font-weight: 400;
    font-size: 16px;
    overflow-x: auto;
}

.deleteRoleBtn {
    color: #d14141;
    svg {
        margin-right: 5px;
        top: -2px;
        position: relative;
    }

    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer !important;
}

.phoenix-form .form-group .custom-input .k-input-value-text {
    max-width: calc(100% - 35px);
}

.view-idoc-btn {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    width: 17px;
    height: 14px;
    display: inline-block;
    padding: 0;
    // background-image: url('/assets/images/eye-blue.svg');
    margin-left: 1px;
    background-color: transparent;
    border: none;
    margin-right: 6px;
}

.k-dialog-wrapper {
    .k-dialog-content {
        -ms-overflow-style: thin;
        scrollbar-width: thin;
        scrollbar-color: #d3d5dd #f1f1f1;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a7a7a7;
            border-radius: 20px;
        }
    }
}
.google-address-list {
    background: #ffffff;
    border: 1px solid rgba(210, 218, 227, 0.75);
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    padding: 20px;
    &:empty {
        display: none;
    }
    div {
        padding: 5px 0;
    }
}

.broker-value-email {
    display: inline-block !important;
    word-wrap: break-word;
    white-space: initial;
    width: 100%;
}

.inititials-img {
    width: 40px;
    height: 40px;
    background: #e6e9f7;
    color: #00106e;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

@media screen and (max-width: 767px) {
    .k-button-solid-primary,
    .k-button-outline-primary {
        min-height: inherit !important;
    }
    .top-title-sec,
    .contact-top-title-sec {
        .title-back-btn,
        .title-sec {
            h1 {
                font-size: 22px;
                span {
                    display: none;
                }
            }
            .back-btn {
                font-size: 14px;
                background-size: 6px;
                padding-left: 15px;
                span {
                    display: none;
                }
            }
        }
        .right-btn-group {
            gap: 10px;
            display: flex;
            justify-content: flex-end;
            .k-button-outline-primary {
                margin-right: 0;
            }
            .k-button-solid-primary,
            .k-button-outline-primary {
                padding: 8px 15px;
                .k-button-text {
                    span {
                        display: none;
                    }
                }
            }
            .white-edit-icon-btn,
            .white-add-icon-btn {
                padding-left: 38px;
            }
            .more-action-links {
                padding-right: 0px !important;
                .k-button-outline,
                .k-button-outline:hover {
                    background-image: url("/assets/images/moreDots.svg") !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 24px !important;
                    width: 24px;
                    height: 30px;
                    padding: 5px 0px 5px 0;
                    &:before {
                        display: none;
                    }
                    .k-button-text {
                        display: none;
                    }
                }
                &:hover,
                &:focus {
                    .k-button-outline {
                        background-image: url("/assets/images/moreDots.svg") !important;
                    }
                }
            }
            .delete-btn-link {
                min-width: 32px;
                min-height: 32px;
                background-position: center;
                background-size: 18px;
                padding: 0;
                .k-button-text {
                    display: none;
                }
            }
        }
    }
    .title-mob-space-none {
        padding-top: 0 !important;
    }
    .form-wrapper {
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .phoenix-form-card {
        padding: 20px;
        .form-title {
            font-size: 20px;
            line-height: 18px;
            margin-bottom: 0;
            margin-top: 5px;
        }
        .form-title.mt-5 {
            margin-top: 2.2rem !important;
        }

        .add-member-group {
            .action-btn-space {
                .form-group {
                    max-width: calc(100% - 60px);
                }
            }
            .row-action-btn {
                left: calc(100% - 52px);
            }
        }
    }
    .phoenix-form {
        .add-role-btn {
            // padding-right: 60px;
        }
        .remove-team-btn {
            margin-bottom: 0;
            // padding-right: 60px;
        }
    }
    .user-dl-img .delete-doc {
        min-height: 30px !important;
    }
    .common-alert-msg .alert {
        font-size: 14px;
        line-height: 16px;
        padding: 12px 12px 12px 40px;
        background-position: top 12px left 15px;
        background-size: 16px !important;
        z-index: 999;
    }
}

// Accordion UI
.permissionsList {
    .k-expander {
        border-color: transparent;
        background: #f9fafc;
        border-radius: 4px;
        margin-top: 15px;

        .k-expander-header {
            background: rgba(238, 240, 248, 0.85);
            border-radius: 4px;
            padding: 10px 15px 10px 20px;
            min-height: 64px;

            .k-expander-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $dark-blue;
                text-transform: inherit;

                .permissionsListHead {
                    display: flex;
                    align-items: center;

                    label {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .k-expander-indicator {
                .k-icon {
                    color: $deep-blue;
                }
            }
        }

        .k-checkbox-md {
            width: 16px;
            height: 16px;
            border: 2px solid $primary-light-blue;
            border-radius: 2px;
            margin-top: -3px;
            margin-right: 12px;

            &:checked {
                background-color: $primary-light-blue;
            }
        }
        .k-checkbox.k-indeterminate {
            border-color: $primary-light-blue;
            color: $white-color;
            background-color: $primary-light-blue;
        }
    }
    .k-expander.k-disabled {
        .k-checkbox-md:checked,
        .k-checkbox.k-indeterminate {
            background-color: #b4b6c3;
        }
        .k-checkbox-md {
            border: 2px solid #b4b6c3;
        }
    }

    .k-expander.k-focus {
        box-shadow: none;
    }

    .k-expander-content {
        padding: 20px;

        .permissionsCatSec {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            h4 {
                font-weight: 400;
                font-size: 18px;
                line-height: 17px;
                color: #000000;
            }

            .permissionCatLists {
                .catCol {
                    background: $white-color;
                    border: 0.5px solid $grey-light-border;
                    border-radius: 5px;
                    height: 62px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    position: relative;
                    margin: 11px 0;

                    label {
                        color: $primary-light-blue;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        padding-right: 20px;
                    }

                    .catActionSec {
                        position: absolute;
                        right: 8px;

                        .moreIconDropdownBtn {
                            .k-button {
                                &:focus,
                                &:hover {
                                    background-color: transparent !important;
                                    border: none !important;

                                    &::before,
                                    &::after {
                                        display: none !important;
                                    }
                                }
                            }
                        }

                        .actionsList {
                            background: $white-color;
                            border: 1px solid $grey-light-border;
                            box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
                            border-radius: 5px;
                            padding: 20px;
                            display: none;
                        }
                    }
                }
            }
        }
        .permissionSubLevelSec {
            padding-bottom: 30px;
            &:last-child {
                padding-bottom: 5px;
            }
            .subLevelHead {
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                color: $dark-blue;
                .k-checkbox-label {
                    margin-inline-start: 0;
                }
            }
            .selectPermissionGroup {
                padding-left: 15px;
                .row .col-lg-6 {
                    padding-left: 25px;
                }
                .fieldLabelGrey {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 11px;
                    color: $label-color;
                    margin: 30px 0 8px 0;
                }
                .k-checkbox-label {
                    margin-inline-start: 0;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 14px;
                    color: $dark-blue;
                }
                .form-group.d-flex {
                    justify-content: flex-end;
                }
                .customInput.inputBorderNone {
                    border: none;
                    padding: 0;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    width: auto;
                    color: $label-color;
                    font-weight: 500;
                    background: transparent;
                    min-width: 120px;
                    .k-input-inner {
                        .k-input-value-text {
                            max-width: 100%;
                            font-size: 14px;
                            line-height: 13px;
                            font-weight: 400;
                            text-align: right;
                        }
                    }
                    .k-button {
                        position: initial;
                        padding: 0;
                        height: 14px;
                        width: 14px;
                        background-size: 10px;
                        background-color: transparent;
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}
//End
//Permission css
.userPermissionsList {
    margin-top: 20px;
    border: none;

    .k-panelbar-header {
        .k-link {
            background: rgba(238, 240, 248, 0.85) !important;
            border-radius: 4px;
            padding: 0px 15px;
            min-height: 46px;

            .k-panelbar-item-text {
                width: 100%;

                .panelTitleSec {
                    display: flex;
                    align-items: center;

                    img {
                        width: 15px;
                    }

                    strong {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: $primary-dark-blue;
                        padding-left: 12px;
                    }
                }
            }

            .k-icon {
                color: $deep-blue !important;
            }
        }

        .k-link.k-focus {
            box-shadow: none;
        }
    }

    .permissionsCatSec {
        border-color: transparent;
        background: #f9fafc;
        border-radius: 4px;
        padding: 20px;

        h4 {
            font-weight: 400;
            font-size: 18px;
            line-height: 17px;
            color: #000000;
        }

        .permissionCatLists {
            .catCol {
                background: $white-color;
                border: 0.5px solid $grey-light-border;
                border-radius: 5px;
                height: 62px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                position: relative;
                margin: 11px 0;

                .label {
                    color: $primary-light-blue;
                    font-weight: 600;
                    font-size: 14px;
                    padding-top: 3px;
                    margin-bottom: 0px;
                    margin-left: 5px;
                }

                .catActionSec {
                    position: absolute;
                    right: 8px;

                    .moreIconDropdownBtn {
                        .k-button {
                            &:focus,
                            &:hover {
                                background-color: transparent !important;
                                border: none !important;
                                &::before,
                                &::after {
                                    display: none !important;
                                }
                            }
                        }
                    }

                    .actionsList {
                        background: $white-color;
                        border: 1px solid $grey-light-border;
                        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
                        border-radius: 5px;
                        padding: 20px;
                        display: none;
                    }
                }
            }
        }
    }
}
.combined-permission .k-panelbar-content {
    background: rgba(238, 240, 248, 0.85) !important;
}
.spiner-animation {
    margin: 15px 0;
    animation: spiner-rotate 5s infinite;
    display: inline-block;
}

@keyframes spiner-rotate {
    50% {
        transform: rotate(360deg);
    }
}
.role-trash-button {
    color: #d14141;
    cursor: pointer;
}

.edit-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 5px;
    gap: 4px;
    height: 18px;
    color: #ffffff;
    background: #001489;
    border-radius: 3px;

    span {
        font-size: 11.8px !important;
        font-weight: 600;
        margin-right: 2px;
        line-height: 95.5%;
        padding-top: 2px;
    }
}

.edit-icon {
    background: url("/assets/images/pencil-blue.svg");
}

.edit-icon,
.delete-icon {
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    border: none;
    height: 20px;
    width: 20px;
    padding: 0;
    cursor: pointer;
    font-size: 0;
    text-indent: -999px;
    overflow: hidden;
}

.custom-modal {
    padding: 30px;
    .modal-header {
        border: none;
        padding: 0;
        margin-bottom: 30px;
    }
    .modal-title {
        font-size: 16px;
        text-transform: uppercase;
        color: #00041b;
        letter-spacing: 0.2em;
        font-weight: 400;
        padding-left: 32px;
    }
    .modal-body {
        color: #00041b;
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
        padding: 0;
        margin-bottom: 30px;
    }
    .modal-footer {
        border: none;
        padding: 0;
    }
    .btn {
        padding: 8px 12px 8px 12px;
        text-align: center;
        font-size: 14px;
        min-width: 70px;
    }
}

.delete-modal .btn-primary {
    background: #d14141;
    border-color: #d14141;

    &:hover {
        color: #d14141;
        background: transparent;
    }
}

.delete-modal .modal-title {
    background-image: url("/assets/images/Warning.svg");
    background-repeat: no-repeat;
    background-size: 24px;
}

.generic-modal .modal-title {
    background-image: url("/assets/images/info-cicle-blue.svg");
    background-repeat: no-repeat;
    background-size: 24px;
}

.generic-modal .modal-dialog {
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.delete-modal .modal-dialog {
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.k-grid .k-grid-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.k-grid .k-grid-content::-webkit-scrollbar-track {
    background: transparent;
}

.k-grid .k-grid-content::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 4px;
}

.source-manually-table .k-grid-content::-webkit-scrollbar {
    width: 6px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes pulse {
    50% {
        background: #001489;
    }
}

.table-loading {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 0.25rem solid #b5bde6;
    border-top-color: #001489;
    animation: spin 1s infinite linear;
    margin-left: auto;
    margin-right: auto;
}

.add-contact-details-model {
    .contact-details-inner {
        height: calc(100% - 70px) !important;
        width: 100%;
        .contact-details-group {
            padding-right: 15px;
        }
    }
    .k-dialog {
        position: fixed;
        right: 0;
        width: 100%;
        max-width: 530px;
        top: 0;
        padding-bottom: 90px;
        bottom: 0;
        border-radius: 0;
        border: none;
        background: #ffffff;
        box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);
        .k-window-actions,
        .k-dialog-titlebar-actions {
            margin: 0;
            .k-button {
                opacity: 1;
                padding: 0;
                &:focus,
                &:hover {
                    box-shadow: none !important;
                    background: none !important;
                    &:before,
                    &:after {
                        display: none !important;
                    }
                }
                .k-button-icon {
                    background: url("/assets/images/close-black.svg") !important;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: 16px !important;
                    &::before {
                        display: none;
                    }
                }
            }
        }
        .model-footer-action {
            text-align: right;
            padding: 24px;
            box-shadow: 0px 0px 20px rgba(145, 148, 165, 0.25);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999;
            background: #fff;
            gap: 10px;
            display: flex;
            justify-content: flex-end;
            .k-button {
                font-weight: 600;
            }
        }
        .k-window-content {
            padding: 0px 20px 20px 20px;
            overflow-x: hidden;
            // padding: 0px 9px 24px 24px;
        }
        .k-dialog-titlebar {
            border: none;
            padding: 15px 20px 5px 24px;
            .k-window-title {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: $text-dark-black-color;
                display: unset;
            }
        }
    }
}

.phoenix-form .form-group .k-timepicker {
    .k-button {
        background-image: url("/assets/images/clock-grey.svg");
        transform: none;
    }
}

.phoenix-form .form-group .k-floating-label-container > .k-dropdownlist:has(.k-input-value-text:empty) ~ .k-label {
    top: 16px !important;
    z-index: 0;
    font-size: 16px;
}

// .action-icon-btn:hover {
//     background-color: transparent !important;
//     border: 1px solid #001489 !important;
// }

.start-sheduler-btn {
    background: url("/assets/images/start-sheduler-blue.svg") !important;
}

.change-sheduler-btn {
    background: url("/assets/images/change-sheduler-blue.svg") !important;
}

.view-note-history-btn {
    background: url("/assets/images/view-note-history.svg") !important;
}

.edit-changed-btn {
    background: url("/assets/images/edit.svg") #dde1f6 !important;
}

.icon-costchange-btn {
    background: url("/assets/images/icon-costchange.svg") #dde1f6 !important;
}

.grid-changed-btn {
    width: 13px;
    height: 13px;
    display: inline-block;
    background-repeat: no-repeat !important;
    background-size: 13px !important;
    background-position: center !important;
    padding: 0 !important;
    border-radius: 0;
}

.icon-costchange-btn {
    width: 14.5px;
    height: 14.5px;
    background-size: 14.5px !important;
}

.phone-icon-btn {
    background: url("/assets/images/u-phone-volume.svg") !important;
    width: 16px;
    height: 18px;
    background-size: 16px !important;
    vertical-align: super;
}
.email-icon-btn {
    background: url("/assets/images/u-email.svg") !important;
    width: 18px;
    height: 18px;
    background-size: 16px !important;
    vertical-align: super;
}
.quote-upload-icon-btn {
    background: url("/assets/images/icon-quoteupload.svg") !important;
    width: 18px;
    height: 18px;
    background-size: 14px !important;
    vertical-align: super;
}

.action-icon-btn {
    display: inline-block;
    background-repeat: no-repeat !important;
    background-position: center !important;
    padding: 0;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
}

.start-sheduler-btn {
    width: 24px;
    height: 24px;
    background-size: 24px;
}

.grid-changed {
    display: inline-block;
    padding: 4.25px !important;
    background: #dde1f6;
    border-radius: 3px;
    left: -3px;
    position: relative;
}

.change-sheduler-btn {
    width: 21px;
    height: 21px;
    background-size: 21px;
    margin-left: 5px;
    margin-right: 5px;
}

.view-note-history-btn {
    width: 22px;
    height: 22px;
    background-size: 22px;
}

.search-form .k-clear-value {
    background: url("/assets/images/icon-close-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    width: 16px !important;
    height: 16px !important;
    opacity: 1;
    .k-icon {
        display: none;
    }
}

.last-child-center .k-grid-header .k-header:last-child {
    justify-content: center !important;
}

.custom-form-card {
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(127, 135, 146, 0.25);
    border-radius: 10px;
    padding: 30px;
    .form-title {
        font-size: 26px;
        line-height: 24px;
        font-weight: 300;
        color: #00041b;
    }
}

.trip-form {
    .trip-label {
        font-size: 12px;
        color: #000837;
        // background: url("/assets/images/substatus.svg");
        background-repeat: no-repeat;
        background-position: top right;
        padding-right: 0px;
        background-size: 15px;
        margin-bottom: 0px;
        display: inline-block;
        strong {
            font-weight: 600;
        }
        .queue-elips {
            width: 5px;
            height: 5px;
            background: #9bcbeb;
            display: inline-block;
            border-radius: 50%;
        }
    }
    .client-name {
        font-size: 12px;
        line-height: 11px;
        font-weight: 600;
        color: #001489;
        strong {
            color: #000837;
        }
    }
}

.create-scenerio-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border: 1px solid #e6e9f7;
    border-radius: 6px;
    padding: 20px;
    height: 51px;
}

.k-popup > .k-timeselector .k-button {
    height: 36px;
}
.custom-color-success {
    background-color: #d4e6da;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
    top: 90px;
    padding: 0;
    z-index: 999999;
}

.Toastify__toast {
    box-shadow: 0px 4px 20px rgba(127, 135, 146, 0.15);
    border-radius: 8px;
    padding: 20px 24px;
    min-height: 61px;
}
.Toastify__toast--success {
    background: #d4e6da;
    color: #1c9240;
}
.Toastify__toast--error {
    background: #f4d9d9;
    color: #d14141;
}
.Toastify__toast-icon {
    margin-inline-end: 10px;
    img {
        width: 19px;
    }
}
.Toastify__toast-body {
    padding: 0;
    margin: 0;
}

.Toastify__toast-body > div:last-child {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
}
.Toastify__close-button {
    display: none;
}

.orange-elips {
    width: 5px;
    height: 5px;
    background: #ffa722;
    display: inline-block;
    border-radius: 50%;
}

.build-split-scenario {
    box-shadow: none;
    border: 1px solid #e6e9f6;
    .build-split-scenario-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 18px;
        color: #00041b;
        position: relative;

        @media screen and (min-width: 967px) {
            flex: 1;
            display: flex;
        }

        .underline {
            color: #82869b;
            border: none;
            position: relative;
            flex: 1;
            border-bottom: 2px solid #001489;
            min-width: 210px;
            margin-left: 10px;
            padding-right: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-flex;
            &:focus {
                outline: none;
            }
        }
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            background-image: url("/assets/images/pencil-blue.svg");
            background-repeat: no-repeat;
            background-size: 16px;
            width: 16px;
            height: 16px;
            right: 0;
            bottom: 5px;
        }
    }
}

.tooltip-inner {
    background: #fff;
    color: #00106e;
    font-weight: 600;
    font-size: 12px;
    padding: 7.5px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border: 1px solid rgba(210, 218, 227, 0.75);
    max-width: 520px;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 2px;
    position: relative;
    z-index: 99;
}

.tooltip-inner p {
    margin-bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-top-color: #fff;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    border-bottom-color: #fff;
}

.bs-tooltip-top .tooltip-arrow::after {
    position: absolute;
    content: "^";
    color: rgba(210, 218, 227, 0.75);
    bottom: -11px;
    left: -1.5px;
    transform: rotate(180deg);
    font-size: 34px;
    font-weight: 100;
    display: inline-block;
    height: 0;
}

.bs-tooltip-bottom .tooltip-arrow::after {
    position: absolute;
    content: "^";
    color: rgba(210, 218, 227, 0.75);
    top: -10px;
    left: -1.5px;
    font-size: 34px;
    font-weight: 100;
    display: inline-block;
    height: 0;
}

.k-button-flat:focus::after,
.k-button-flat.k-focus::after {
    opacity: 0;
}

.k-grid-header .k-sort-order {
    font-size: 8px;
    font-weight: 500;
    color: #001489;
    position: absolute;
    left: auto;
    top: auto;
    padding-left: 12px;
    margin-top: -3px;
}

@media screen and (max-width: 767px) {
    .fs-mob-24 {
        font-size: 24px;
    }
    .custom-modal {
        padding: 12px;
        .modal-header {
            margin-bottom: 12px;
        }
        .modal-body {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 12px;
        }
    }

    .custom-form-card {
        padding: 15px;
    }

    .custom-form-card .form-title {
        font-size: 18px;
        line-height: 20px;
    }

    .build-split-scenario {
        .build-split-scenario-title {
            font-size: 16px;
            line-height: 18px;
            .underline {
            }
        }
    }
}

.custom-table-transparent .k-grid-header {
    background: transparent;
}

@media screen and (max-width: 576px) {
    .create-scenerio-box {
        flex-direction: column;
        height: auto;
        gap: 6px;
        padding: 12px;
    }
}

@media screen and (max-width: 480px) {
}
.delete-btn-link {
    background-image: url("/assets/images/delete-red.svg");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    padding-left: 24px;
    padding-top: 10px;
    background-color: transparent;
    border: none;
    color: #d14141;
    @extend .text-danger;
    &:focus,
    &:hover {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        &::after,
        &::before {
            display: none !important;
        }
    }
}
.profile-pic-upload-outer {
    position: relative;

    .uploaded-profile-img {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99;
        background: url("/assets/images/upload-icon.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 100% !important;
        height: 36px;
        width: 36px;

        img {
            height: 60px;
            width: 60px;
            position: absolute;
            object-fit: cover;
            display: none;

            &:last-child {
                display: block;
            }
        }
    }

    .upload-image-hints {
        position: absolute;
        top: 22px;
        left: 72px;
        pointer-events: none;

        h4 {
            font-weight: 400 !important;
            font-size: 16px;
            margin-bottom: 0px !important;
            color: $text-dark-black-color;

            strong {
                color: $primary-light-blue;
                font-weight: 600;
            }
        }

        p {
            font-weight: 400;
            font-size: 12px;
            color: $label-color;
            margin-bottom: 0;
        }
    }

    .upload-profile-img {
        background: #f9fafc;
        border: 1px dashed rgba(130, 134, 155, 0.7);
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        .k-dropzone {
            position: relative;
            height: 76px;

            .k-upload-button,
            input[type="file"] {
                background-color: transparent;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: none;
                padding: 0;
                box-shadow: none;
                width: 100%;
                opacity: 0;
                height: 100%;
                z-index: 100;
                &:focus {
                    box-shadow: none;
                }

                span {
                    display: none;
                }
            }
            input[type="file"] {
                width: 100%;
                opacity: 0;
                z-index: 99;
                cursor: pointer;
            }

            .k-dropzone-hint,
            .k-upload-status {
                display: none;
            }
        }

        .k-upload-files {
            border: none;
            overflow: initial;
            .k-file {
                border: none;
                background: none;
                padding: 0;
            }
            .k-file-group-wrapper,
            .k-file-icon-wrapper,
            .k-file-name-size-wrapper,
            .k-file-info,
            .k-progressbar,
            .k-upload-pct {
                display: none;
            }
            .k-upload-status,
            .k-upload-actions {
                .k-button {
                    position: absolute;
                    top: -81px;
                    z-index: 999;
                    left: 64px;
                    background: #d14141 !important;
                    color: #fff;
                    border-radius: 100%;
                    width: 18px;
                    height: 18px;
                    .k-icon {
                        font-size: 12px;
                        &:before {
                            content: "\e11b";
                        }
                    }
                }
            }
        }
        .k-actions {
            border-top: 1px dashed rgba(130, 134, 155, 0.7);
            justify-content: center;
            .k-button {
                font-family: $leagueSpartanFont;
                padding: 8px 12px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                border-radius: 4px;
            }
        }
    }

    .upload-Doc-component {
        .k-upload-files {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #f8f9fa;
            z-index: 10;
            padding: 0;
            display: flex;
            align-items: center;

            .k-file {
                padding: 0;
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;

                .k-file-single {
                    padding-right: 20px;

                    .k-progressbar {
                        width: 100%;
                        background: #e8e9ec;
                        height: 6px;
                        bottom: 0;

                        .k-progress {
                            background: linear-gradient(218.8deg, #021bae 3.54%, #031164 66.51%);
                        }
                    }

                    .k-file-group-wrapper {
                        display: none;
                    }

                    .k-file-name-size-wrapper {
                        padding-right: 20px;
                        padding-left: 80px;

                        .k-file-name {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 17px;
                            color: $dark-grey;
                        }

                        .k-file-validation-message {
                            margin-top: 5px;
                        }
                    }

                    .k-upload-status {
                        .k-button-icon {
                            background: #f4d9d9;
                            color: $error-color;
                            width: 28px;
                            height: 28px;
                            overflow: hidden;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-left: 1px;

                            &:hover {
                                background: $error-color;
                                color: #f4d9d9;
                            }
                        }
                    }
                }
            }
        }
    }
    .document-pdf-view {
        width: 100%;
        min-width: 1000px;
        height: calc(100vh - 170px);
    }
}
.add-role-btn {
    display: flex;
    margin-top: 10px;
    margin-bottom: -5px;
    justify-content: flex-end;

    .btn-link {
        background-image: url("/assets/images/pluse-circle-blue.svg");
        background-repeat: no-repeat;
        background-position: left;
        // background-size: 18px;
        // padding-left: 25px;
        // padding-top: 5px;
        background-size: 16px;
        padding-left: 20px;
        padding-top: 3px;
    }
}
.remove-first-option .k-input-values {
    .k-chip:first-child .k-chip-actions {
        display: none;
    }
}
// .k-dateinput {
//     pointer-events: none;
// }

.leg-list-table thead .partial-selection .k-checkbox[aria-checked="false"] {
    background-image: url("/assets/images/partial-selection.svg") !important;
    background-color: #001489;
    box-shadow: none;
    border-color: #001489;
}
.scenario-operator-list-table .partial-selection .k-checkbox[aria-checked="false"] {
    background-image: url("/assets/images/partial-selection.svg") !important;
    background-color: #001489;
    box-shadow: none;
    border-color: #001489;
}
.k-searchbox .k-input-inner {
    padding-left: 0;
}

.custom-popover .popover-body {
    padding: 0;
    background: #ffffff;
    border: 1px solid rgba(210, 218, 227, 0.75);
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    width: 100%;
    .k-floating-label-container {
        padding: 0;
        width: 100%;
        position: relative;
        min-height: 48px;
    }
    .swc-ms-cross-button {
        right: 50px;
    }
    .k-picker-solid {
        background: #ffffff;
        border: none;
        border-radius: 4px;
        height: 100%;
        position: relative;
    }
    .k-label {
        position: absolute !important;
        left: 12px !important;
        top: 6px;
    }
}

.custom-popover ~ .k-animation-container {
    z-index: 999991 !important;
}

.custom-popover {
    padding: 14px;
    min-width: 380px;
    width: 380px;
    .popover-header {
        padding: 12px;
    }
}

.bg-light-blue {
    background: rgba(238, 240, 248, 0.85);
}

.popover-body .k-icon {
    background-image: url("/assets/images/down-angle-grey.svg");
    background-repeat: no-repeat;
    transform: rotate(360deg);
    background-position: center;
    background-size: 14px;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    background-color: inherit;
    z-index: 9;
    border: none;
    &::before {
        display: none;
    }
}

.popover-body .k-dropdownlist[aria-expanded="true"] .k-icon {
    transform: rotate(180deg);
    top: 8px;
}

.profile-pic-upload-outer .k-upload-files::-webkit-scrollbar,
.custom-scrolbar::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.profile-pic-upload-outer .k-upload-files::-webkit-scrollbar-track,
.custom-scrolbar::-webkit-scrollbar-track {
    background: transparent;
}

.profile-pic-upload-outer .k-upload-files::-webkit-scrollbar-thumb,
.custom-scrolbar::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 8px;
}

.k-upload .k-upload-files::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.k-upload .k-upload-files::-webkit-scrollbar-track {
    background: transparent;
}

.k-upload .k-upload-files::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 8px;
}

.table-bg-light-green {
    background: #f4fbf6;
    border: 0.5px solid #1c9240;
    td {
        border-top: 1px solid #1c9240;
        border-bottom: 1px solid #1c9240;
        background-color: inherit;
    }
}

.k-master-row.table-bg-light-green td:first-child {
    border-left: 1px solid #1c9240;
}
.k-master-row.table-bg-light-green td:last-child {
    border-right: 1px solid #1c9240;
}

.table-bg-light-orange {
    background: #fff9ef;
    border: 0.5px solid #ffa722;
    td {
        border-top: 1px solid #ffa722;
        border-bottom: 1px solid #ffa722;
        background-color: inherit;
    }
}

.k-master-row.table-bg-light-orange td:first-child {
    border-left: 1px solid #ffa722;
}
.k-master-row.table-bg-light-orange td:last-child {
    border-right: 1px solid #ffa722;
}

.table-bg-light-white {
    background: rgba(255, 255, 255, 1);
    border: 0.5px solid #d8d8db;
    td {
        border-top: 1px solid #d8d8db;
        border-bottom: 1px solid #d8d8db;
        background-color: inherit;
    }
}

.k-master-row.table-bg-light-white td:first-child {
    border-left: 1px solid #d8d8db;
}
.k-master-row.table-bg-light-white td:last-child {
    border-right: 1px solid #d8d8db;
}

.table-bg-light-gray {
    background: rgba(242, 244, 246, 0.8);
    border: 0.5px solid #b4b6c3;
    td {
        border-top: 1px solid #b4b6c3;
        border-bottom: 1px solid #b4b6c3;
        background-color: inherit;
    }
}

.k-master-row.table-bg-light-gray td:first-child {
    border-left: 1px solid #b4b6c3;
}
.k-master-row.table-bg-light-gray td:last-child {
    border-right: 1px solid #b4b6c3;
}

.profile-pic-upload-outer {
    .k-upload {
        border-color: transparent;
        .k-dropzone {
            height: 76px;
            border-radius: 6px;
            border: 1px dashed rgba(130, 134, 155, 0.7);
            background: #f9fafc;
            margin-bottom: 10px;
            .k-upload-button {
                top: 0 !important;
                left: 0 !important;
                width: 100%;
                height: 100%;
                z-index: 100;
                opacity: 0;
            }
        }
        .k-upload-status {
            display: none;
        }
        .k-dropzone-hint {
            display: none;
        }
    }
    .k-upload-files {
        border: none;
        overflow: hidden;
        overflow-y: auto;
        li {
            background: rgba(238, 240, 248, 0.85);
            border: 1px solid #cdd2e1;
            min-height: 48px;
            border-radius: 6px;
            padding: 5px 30px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            position: relative;
            padding-top: 10px;
            .k-file-single {
                justify-content: unset;
                display: flex;
            }
        }
        .k-file-info {
            display: inline-flex;
            flex-direction: column;
            padding-left: 42px;
            span{
                line-height: 11px;
            }
        }
        .k-file-validation-message {
            color: #dc3545;
            font-size: 11px;
            line-height: 11px;
        }
    }
    .k-upload-actions {
        button {
            background-image: url("/assets/images/u-trash-alt.svg") !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: 13px !important;
            background-color: #fadede !important;
            border: none;
            width: 25px !important;
            height: 25px !important;
            border-radius: 50%;
            right: 20px !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            position: absolute;
            .k-i-x::before {
                display: none;
            }
        }
    }
    .k-file-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 13px;
        color: #00041b;
        width: 300px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
    }
    .k-file-icon {
        background-image: url("/assets/images/u-file-upload-alt.svg") !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: 21px !important;
        width: 21px;
        height: 21px;
        margin-right: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            display: none;
        }
    }
}

.k-upload .k-upload-files .k-file:last-child {
    border-width: 1px;
}

.divider-hr {
    background: #e6e9f6;
    width: 100%;
    height: 1px;
    display: block;
}

.trip-collapse-hr.divider-hr {
    background: #dadce7;
}

.orange-info {
    color: #e28f10;
}

.k-grid td p {
    text-align: inherit;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.modal-backdrop {
    z-index: 99999;
}
.modal {
    z-index: 99999;
}

.scenario-operator-list-table .k-master-row td {
    height: 60px;
}

.border-radius-5 {
    border-radius: 5px;
}

.dolar-text {
    position: relative;
}

.dolar-text input {
    padding-left: 22px !important;
}

.dolar-text::after {
    position: absolute;
    content: "$";
    display: inline-block;
    left: 15px;
    font-size: 14px;
    top: 20px;
}

.onewayTripIcon {
    background-image: url("/assets/images/arrowsRight.svg");
}
.roundTripIcon,
.onewayTripIcon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px;
    width: 16px;
    height: 10px;
    display: inline-block;
    margin: 0px 3px;
}
.roundTripIcon {
    background-image: url("/assets/images/arrowsLeftRight.svg");
}
.divider {
    background-color: #82869b;
    width: 1px;
    height: 10px;
    display: inline-block;
    margin: 0 5px;
}

.custom-status-popover.popover-body {
    padding: 0;
    background: #ffffff;
    border: 1px solid rgba(210, 218, 227, 0.75);
    box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
    border-radius: 5px;
    width: 100%;
    .k-input-solid {
        border: none;
        padding: 6px;
        padding-right: 40px;
    }
    .swc-ms-cross-button {
        top: 50%;
        transform: translateY(-50%);
        right: 29px;
    }
    .swc-ms-up-button {
        background-image: url("/assets/images/down-angle-grey.svg");
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 999;
        cursor: pointer;
        background-position: center;
        path {
            display: none;
        }
    }
    .swc-ms-down-button {
        background-image: url("/assets/images/up-angle-grey.svg");
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;
        z-index: 999;
        background-position: center;
        path {
            display: none;
        }
    }
    .k-multiselect .k-selection-multiple {
        margin-top: 20px;
    }
    .k-label {
        top: 6px !important;
        transform: unset !important;
        left: 10px !important;
    }
}

.operetor-list-icon {
    .operetor-icon-bg-lightblue {
        background: #f0f1f8;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 2px 8px;
        margin-right: 12px;
        cursor: pointer;
        &:hover {
            transition: 0.1s;
            border: 1px solid #001489;
            background: var(--lightish-blue, #dde1f6);
        }
        &:active {
            transition: 0.1s;
            border: 1px solid #001489;
            background: var(--lightish-blue, #dde1f6);
        }
    }
}

.sub-status {
    margin-left: 5px;
}

.k-panelbar > .k-item > .k-link:focus,
.k-panelbar > .k-item > .k-link.k-focus,
.k-panelbar > .k-panelbar-header > .k-link:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-focus {
    box-shadow: none !important;
}

.profile-pic-upload-outer .k-upload-files ul {
    list-style: none;
    padding-left: 0;
    max-height: 217px;
    margin-bottom: 0;
}

.custom-icon-hide .k-timepicker .k-button {
    display: none;
}

.appearance-hide::-webkit-outer-spin-button,
.appearance-hide::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.k-upload .k-upload-files {
    max-height: 260px;
}

.w-550px.add-contact-details-model .k-dialog {
    max-width: 550px;
}

.w-750px.add-contact-details-model .k-dialog {
    max-width: 750px;
}

.w-950px.add-contact-details-model .k-dialog {
    max-width: 950px;
}

.add-contact-details-model {
    .k-dialog {
        // height: calc(100vh - 90px);
    }

    .custom-search-box {
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 4px 30px 0px rgba(211, 211, 211, 0.25);
        padding: 15px 20px;

        .k-textbox {
            padding: 8px 15px 6px;
            color: #6f748e;
            font-size: 16px;
            font-family: League Spartan;
            font-style: normal;
            font-weight: 400;
            .k-input-inner {
                padding: 0;
            }
            .k-input-prefix {
                img {
                    position: relative;
                    top: -2px;
                }
            }
        }
    }
    .phoenix-form {
        max-width: 100%;
    }

    .arrow-dropdown {
        .k-dropdown-button {
            .k-button-flat-base {
                background: url("/assets/images/carrot-icon.svg") !important;
                background-repeat: no-repeat !important;
                background-position: center right !important;
            }
        }
    }
    .k-grid table {
        min-width: auto;
        background: #fff;
    }

    .custom-main-search-item {
        height: 300px;
        .search-icon {
            margin-bottom: 44px;
        }
    }
}

.add-new-operatior-btn {
    border-radius: 5px;
    border: 1px dashed rgba(0, 20, 137, 0.4);
    background: rgba(215, 218, 235, 0.24);
    padding: 12px 20px;
    height: 48px;
    display: flex;
    align-items: center;
}

.hide-scroll-on-modal-open {
    overflow: hidden !important;
}

.custom-popover span.k-input-inner {
    min-height: 48px;
    padding-right: 40px;
}

.k-input-solid:focus-within {
    border-color: inherit;
    box-shadow: none;
}

.hide-scroll-on-modal-open {
    overflow: hidden !important;
}

.scroll_id span.k-input-value-text {
    position: relative;
    top: 9px;
    left: 0px;
}

.k-window-titlebar {
    white-space: unset;
}

.add-contact-details-model .k-window-title {
    .scroll_id span.k-input-value-text {
        position: relative;
        top: 9px;
        left: 3px;
    }
}

.phoenix-form .form-group.dolar-text .k-floating-label-container.k-empty > .k-label {
    font-size: 12px !important;
    top: 6px;
    line-height: 18px !important;
}
.hidetooltip {
    z-index: 99;
}
.zindex999 {
    z-index: 999;
}
.k-maskedtextbox.k-invalid {
    border-color: inherit;
}

.k-input-solid:focus,
.k-input-solid.k-focus {
    box-shadow: none;
}

.k-searchbox {
    border-color: #cdd2e1;
}

.add-contact-details-model .k-dialog .model-footer-action {
    left: auto !important;
    position: fixed !important;
}

.add-contact-details-model.w-750px .k-dialog .model-footer-action {
    max-width: 750px;
    width: 100%;
}

.add-contact-details-model.w-950px .k-dialog .model-footer-action {
    max-width: 950px;
    width: 100%;
}

.add-contact-details-model.w-550px .k-dialog .model-footer-action {
    max-width: 550px;
    width: 100%;
}

.custom-wrapped-truncated {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.custom-wrapped-truncated-line3 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre !important;
    height: fit-content;
}

.cross-icon-relative {
    position: relative;
    .swc-ms-cross-button {
        top: 58%;
        right: 44px;
        transform: unset;
    }
}

.swc-ms-cross-button {
    top: 50%;
    transform: translateY(-50%);
    right: 36px;
    background: url("/assets/images/icon-close-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    height: 20px !important;
    path {
        display: none;
    }
}

.align-text-top img {
    vertical-align: baseline;
}

.vertical-sub {
    vertical-align: sub;
}

.build-split-scenario .main-custom-accordian .parent-custom-accordian-sub .k-panelbar-header > .k-link {
    background: rgba(238, 240, 248, 0.85) !important;
    padding-left: 20px;
    padding-right: 20px;
}

.build-split-scenario .main-custom-accordian .parent-custom-accordian-sub .k-panelbar-item {
    border: none;
}

.build-split-scenario .main-custom-accordian > .parent-custom-accordian-sub {
    padding: 0 !important;
}

.main-combined-scenario .k-panelbar-header > .k-link {
    padding-top: 10px;
    padding-bottom: 10px;
}

.k-master-row td .k-checkbox,
.k-grid-header .k-header .text-center .k-checkbox {
    margin-right: 0 !important;
}

.build-split-scenario > .k-panelbar > .k-item > .k-link.k-selected,
.build-split-scenario > .k-panelbar > .k-panelbar-header > .k-link.k-selected {
    padding: 0;
    background: inherit;
    display: block;
    padding-right: 30px;
    .k-icon {
        font-size: 20px;
        position: absolute;
        right: 0;
        top: 50%;
        color: #001489;
        font-weight: 500;
        cursor: pointer;
        transform: translateY(-50%);
    }
}

.build-split-scenario .k-panelbar {
    border: none !important;
}

.build-split-scenario > .k-panelbar > .k-item > .k-link,
.build-split-scenario > .k-panelbar > .k-panelbar-header > .k-link {
    display: block;
    padding: 0;
}

.k-multiselect .k-selection-multiple .k-chip-solid-base {
    position: relative;
    padding: 8px 12px 7px 7px;
    height: 20px;
}

.k-multiselect .k-selection-multiple .k-chip-solid-base .k-chip-actions .k-chip-remove-action .k-i-x-circle {
    position: absolute;
    top: 10.5px;
    line-height: 20px;
    display: inline-block;
    height: 20px;
}

.build-split-scenario > .k-panelbar > .k-item > .k-link:hover,
.build-split-scenario > .k-panelbar > .k-item > .k-link.k-hover,
.build-split-scenario > .k-panelbar > .k-panelbar-header > .k-link:hover,
.build-split-scenario > .k-panelbar > .k-panelbar-header > .k-link.k-hover {
    background-color: inherit;
}
.upDownIcon {
    z-index: 999;
    position: relative;
    width: 35px;
    .swc-ms-up-button {
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 999;
        cursor: pointer;
        z-index: 999;
        color: #00106e;
        top: 3px;
    }
    .swc-ms-down-button {
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;
        z-index: 999;
        color: #00106e;
        margin-top: 2px;
    }
}
.main-box-shadow {
    box-shadow: 0px 4px 40px 0px rgba(127, 135, 146, 0.25);
    border-radius: 10px;
}

.large-padding {
    padding-top: 100px;
    padding-bottom: 134px;
}

.filter-right-btn-sec .btn-outline-primary {
    line-height: 16px;
}

.custom-status-popover .k-multiselect .k-selection-multiple .k-chip-solid-base .k-chip-actions .k-chip-remove-action .k-i-x-circle {
    position: absolute;
    top: 10.5px;
    line-height: 20px;
    display: inline-block;
    height: 20px;
}

.filter-middle {
    .quick-filter-box {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.border-right-filter {
    position: relative;
    margin-right: 12px;
    &::after {
        content: "";
        position: absolute;
        right: -18.5px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 1px;
        height: 27px;
        background: #e6e9f6;
    }
}

.relative-icon-minus {
    position: relative;
    top: -1px;
    span {
        position: relative;
        top: -1px;
    }
}
.relative-icon-minus-2 {
    position: relative;
    top: -2px;
    span {
        position: relative;
        top: -2px;
    }
}
.relative-icon-minus-3 {
    position: relative;
    top: -3px;
    span {
        position: relative;
        top: -3px;
    }
}
.relative-icon-plus {
    position: relative;
    top: 1px;
    span {
        position: relative;
        top: 1px;
    }
}
.relative-icon-plus-2 {
    position: relative;
    top: 2px;
    span {
        position: relative;
        top: 2px;
    }
}

.add-contact-details-model .custom-search-box .k-clear-value {
    top: 50%;
    right: 36px;
    background: url("/assets/images/icon-close-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    width: 26px;
    height: 26px;
    opacity: 1;
    .k-icon {
        display: none;
    }
}

.overflow-x-scroll {
    overflow-x: auto;
}

.text-capatalize {
    text-transform: capitalize;
}

.data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data .quick-filter-sec .quick-filter-box.close img {
    height: 9px;
}

.custom-disabled.k-button:disabled,
.custom-disabled.k-button.k-disabled {
    background: #82869b;
    border-color: transparent;
    opacity: 1;
}

.custom-accordian-sub span.k-link.k-selected {
    padding-left: 22px !important;
}

.k-grid-header {
    width: calc(100% - 6px);
}

.op-grade {
    width: 23px;
    height: 23px;
    line-height: 25px;
    background: #dde1f6;
    color: #00106e;
    font-size: 11px;
    font-weight: 700;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    position: relative;
    top: -1px;
}

.tooltip {
    position: absolute !important;
    inset: auto auto 0px 0px !important;
    overflow: hidden !important;
    z-index: 991;
}

.tooltip-auto-inset {
    inset: 0px auto 0px 0px !important;
}

.k-grid-header .k-header {
    padding-bottom: 10px;
}

.custom-select {
    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        .main-list {
            background: transparent;
            border-radius: 4px;
            color: #6f748e;
            font-weight: 400;
            font-size: 16px;
            z-index: 2;
            font-family: "League Spartan", sans-serif;
            background-image: url("/assets/images/down-angle-grey.svg");
            background-repeat: no-repeat;
            background-position: 95% center;
            transform: rotate(360deg);
            background-size: 14px;
            position: relative;
            border: 1px solid #cdd2e1;
            > a {
                padding: 10px 14px 8px 14px;
                display: inline-block;
                width: 100%;
                border-radius: 4px;
            }
        }
    }
    a {
        color: inherit;
    }
    .custom-select-dropdown {
        padding: 16px 14px 6px;
        position: absolute;
        top: 42px;
        background: #fff;
        border: 1px solid #001489;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 9;
        border-top: 1px solid #cdd2e1;
        opacity: 0;
        transition: all 0.5s;
        li {
            margin-bottom: 18px;
            a {
                line-height: 15px;
                display: inline-block;
                &:hover {
                    color: #001489;
                }
            }
        }
    }
}
.custom-select ul .main-list.active-select-option {
    border: 1px solid #001489;
    border-bottom: 1px solid #cdd2e1;
    background-image: url("/assets/images/up-angle-grey.svg");
}
.custom-select ul .main-list.active-select-option .custom-select-dropdown {
    opacity: 1;
    transition: opacity 0.15s linear;
}

.k-grid-header .k-header {
    padding-bottom: 10px;
}

.v-align-unset {
    vertical-align: unset !important;
}

.btn-icon {
    background: transparent;
    border: none;
    padding: 0;
    &:hover,
    &:focus {
        background: inherit;
        border: inherit;
    }
}

.graph-icon-scenario span {
    position: relative;
    top: -4px;
}

.tooltip101 {
    z-index: 10002 !important;
}
.notes-td-cell {
    .notes-form-group {
        position: relative;
        input {
            border-radius: 4px;
            border: 1px solid #001489;
            height: 36px;
            padding-right: 32px;
            font-size: 14px;
            color: #001489;
            font-family: "League Spartan", sans-serif;
            padding-top: 2px;
            padding-bottom: 0;
        }
        .check-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
        }
    }
}

.custom-select-dropdown {
    .k-picker-solid {
        border: 1px solid #cdd2e1;
        color: #00041b;
        font-family: "League Spartan", sans-serif;
        font-size: 13px;
        background-color: #fff;
        &:focus {
            box-shadow: none;
        }
    }
}

.custom-select-dropdown .k-picker-solid[aria-expanded="true"] {
    border: 1px solid #001489;
    border-bottom: 1px solid #cdd2e1;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.custom-select-dropdown {
    .k-picker-solid {
        min-width: 120px;
        width: 150px;
        height: 30px;
    }
    .k-picker-md .k-input-inner {
        padding-top: 2px;
        padding-bottom: 0;
    }
}
.k-window-content {
    padding-right: 24px !important;
}

.custom-select-dropdown .k-picker-solid .k-icon {
    background-image: url("/assets/images/down-angle-blue.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    top: 12px;
    position: absolute;
    min-height: unset;
    right: 0;
}

.custom-select-dropdown .k-picker-solid .k-icon::before {
    content: "";
}

.custom-select-dropdown .k-picker-solid[aria-expanded="true"] .k-icon {
    background-image: url("/assets/images/up-angle-blue.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    top: 12px;
    position: absolute;
    min-height: unset;
    right: 0;
}

.notes-td-cell {
    padding-right: 0 !important;
    .pencil-icon-main {
        display: inline-grid;
        grid-template-columns: auto 22px;
        align-items: center;
        cursor: pointer;
        .custom-wrapped-truncated {
            line-height: 14px;
        }
    }
    .custom-select-dropdown {
        .k-picker-solid {
            min-width: 120px;
            width: 150px;
            height: 30px;
        }
        .k-picker-md .k-input-inner {
            padding-top: 2px;
            padding-bottom: 0;
        }
    }
    .k-window-content {
        padding-right: 24px !important;
    }

    .custom-select-dropdown .k-picker-solid .k-icon {
        background-image: url("/assets/images/down-angle-blue.svg");
        background-size: 10px;
        background-repeat: no-repeat;
        top: 12px;
        position: absolute;
        min-height: unset;
        right: 0;
    }

    .custom-select-dropdown .k-picker-solid .k-icon::before {
        content: "";
    }

    .custom-select-dropdown .k-picker-solid[aria-expanded="true"] .k-icon {
        background-image: url("/assets/images/up-angle-blue.svg");
        background-size: 10px;
        background-repeat: no-repeat;
        top: 12px;
        position: absolute;
        min-height: unset;
        right: 0;
    }

    .thead-text-center {
        justify-content: center;
    }
    .text-elips {
        text-align: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.graph-modal ~ .k-animation-container,
.k-animation-container.custom-k-animation-container {
    .k-popup .k-list-content .k-focus.k-list-item {
        background: unset !important;
    }
    .k-popup {
        // max-height: 250px;
        background: $white-color;
        border: none;
        box-shadow: 0px 4px 20px rgba(169, 181, 194, 0.25);
        border-radius: 5px;
        padding: 0px;

        .k-list-content {
            -ms-overflow-style: thin;
            scrollbar-width: thin;
            scrollbar-color: #d3d5dd #f1f1f1;

            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #d3d5dd;
                border-radius: 20px;
            }
            .k-list-ul {
                margin: 0;
                border: 1px solid #001489;
                border-top: 0px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;

                .k-list-item {
                    padding: 4px 12px;

                    .k-list-item-text {
                        font-weight: 400;
                        font-size: 13px;
                        color: #6f748e;
                        &:hover {
                            color: #001489;
                        }
                    }

                    &:hover {
                        background-color: inherit;
                    }
                }

                .k-selected {
                    background-color: #eef1ff;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    .k-list-item-text {
                        color: #000c52;
                        font-weight: 600;
                    }
                }
            }

            .k-focus.k-list-item {
                box-shadow: none;
            }
        }
    }
}

.view-history-notes-modal {
    .enter-input {
        border-radius: 5px;
        border: 1px dashed rgba(210, 218, 227, 0.75);
        background: #fff;
        padding: 12px;
        padding-top: 6px;
        textarea {
            border: none;
            font-size: 14px;
            resize: none;
            &:focus-visible {
                outline-width: 0;
            }
        }
        .save-btn {
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: #b4b6c3;
            position: relative;
            background-image: url("/assets/images/arrow-right-notes.svg");
            background-repeat: no-repeat;
            background-position: bottom right;
            padding-right: 15px;
            line-height: 11px;
        }
    }
    .timeline-items {
        padding: 12px 7px;
        border: 0.5px solid;
        border-radius: 5px;
        background: #fff;
        margin-bottom: 10px;
        border-left: 5px solid;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .notes-dropdown {
        background: transparent;
        border: none;
        color: #b4b6c3;
        font-size: 12px;
        font-weight: 500;
        width: auto;
        position: relative;
        padding-right: 20px;
        margin-right: 12px;
        .k-input-inner {
            padding: 0;
            display: unset;
        }
        .k-icon-button {
            padding: 0;
            .k-icon {
                min-height: unset;
                position: absolute;
                background-image: url("/assets/images/angle-down-notes.svg");
                background-repeat: no-repeat;
                background-position: center;
                margin-left: 12px;
                top: 2.5px;
                &::before {
                    content: "";
                }
            }
        }
    }
    .main-timeline-box {
        .enter-input.active {
            border: 1px dashed rgba(0, 20, 137, 1);
            input {
                color: #00041b;
            }
            .save-btn.active {
                color: #001489;
                font-weight: 600;
                background-image: url("/assets/images/arrow-right-notes-blue.svg");
            }
        }
        .notes-dropdown.active {
            color: #001489;
            .k-icon-button {
                .k-icon {
                    background-image: url("/assets/images/angle-down-notes-blue.svg");
                }
            }
        }
    }

    .notes-panel-wrapper {
        .k-panelbar {
            background: transparent;
            border: none;
            padding: 0;
            .k-link {
                padding: 0;
                background: transparent;
                color: inherit;
            }
        }
    }

    .k-form-error {
        color: #d14141;
    }
}

.view-history-notes-modal .enter-input textarea::placeholder {
    color: #82869b;
}
.view-history-notes-modal .enter-input.active textarea::placeholder {
    color: #00041b;
}

.notes-panel-wrapper .k-panelbar > .k-item > .k-link.k-selected,
.notes-panel-wrapper .k-panelbar > .k-panelbar-header > .k-link {
    color: #00041b;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
}

.notes-panel-wrapper .k-panelbar > .k-item > .k-link.k-selected:hover,
.notes-panel-wrapper .k-panelbar > .k-item > .k-link.k-selected.k-hover,
.notes-panel-wrapper .k-panelbar > .k-panelbar-header > .k-link.k-selected:hover,
.notes-panel-wrapper .k-panelbar > .k-panelbar-header > .k-link.k-selected.k-hover {
    background-color: transparent;
}

.notes-panel-wrapper .k-link .k-icon {
    color: #001489 !important;
    font-weight: 500 !important;
}

.view-history-notes-modal {
    .timeline-items {
        .action-box {
            position: absolute;
            top: 6px;
            right: 10px;
            opacity: 0;
            display: flex;
            gap: 6px;
        }
    }
}

.view-history-notes-modal .timeline-items:hover .action-box {
    opacity: 1;
}

.view-history-notes-modal .timeline-items.purple-blue {
    border-color: #6e8df8;
}

.view-history-notes-modal .timeline-items.scenario-note-blue {
    border-color: #6ad1fd;
}

.view-history-notes-modal .timeline-items.light-gray {
    border-color: #d5dfe9;
}

.view-history-notes-modal .timeline-items {
    &:hover {
        border-color: #001489;
    }
}

.k-picker-solid:focus,
.k-picker-solid.k-focus {
    box-shadow: none;
}

.view-history-notes-modal ~ .k-animation-container {
    min-width: 140px !important;
    font-size: 12px;
    .k-popup {
        .k-list-content {
            .k-list-ul {
                .k-list-item {
                    .k-list-item-text {
                        font-size: 12px;
                        color: #b4b6c3;
                        &:hover {
                            color: #001489;
                        }
                    }
                }
            }
        }
    }
}

.topToolTipZ {
    cursor: default;
    z-index: 10004 !important;
}

.icreon-tab .nav-tabs .nav-item .nav-link[data-rr-ui-event-key="trip-notes"] {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #6e8df8;
        border-radius: 50%;
        left: 5px;
        top: 18.5px;
    }
}

.icreon-tab .nav-tabs .nav-item .nav-link[data-rr-ui-event-key="scenario-notes"] {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #6ad1fd;
        border-radius: 50%;
        left: 5px;
        top: 18.5px;
    }
}

.icreon-tab .nav-tabs .nav-item .nav-link[data-rr-ui-event-key="system-notes"] {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #d5dfe9;
        border-radius: 50%;
        left: 5px;
        top: 18.5px;
    }
}

.disableds {
    pointer-events: none;
    opacity: 0.5;
}

.operetor-list-icon {
    .view-note-history-btn {
        width: 21px;
        height: 21px;
        margin-right: 12px;
        margin-left: 10px;
        background-size: 21px !important;
    }
}

.view-history-notes-modal .notes-dropdown[aria-expanded="true"] .k-icon-button .k-icon {
    background-image: url("/assets/images/angle-down-notes.svg");
    transform: rotate(180deg);
}

.view-history-notes-modal {
    .date-notes,
    .notes-text {
        width: calc(100% - 80px);
    }
    .notes-text {
        overflow-x: hidden;
    }
}

.main-dashlet-box {
    background: #fff;
    border: 1px solid #e6e9f6;
    padding: 20px;
    border-radius: 8px;
    .k-grid-header .k-grid-header-wrap {
        display: none;
    }
    .main-title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2.8px;
        text-transform: uppercase;
    }
    .k-master-row td {
        min-height: 54px;
        vertical-align: top;
        text-overflow: inherit;
    }
    .k-grid table {
        min-width: 550px;
        position: relative;
        top: -8px;
    }
    .k-grid tr {
        border-radius: 5px;
        border: 0.5px solid rgba(210, 218, 227, 0.75);
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(169, 170, 181, 0.25);
    }
    .k-panelbar {
        border: 0 !important;
    }
    .view-note-history-btn {
        background-size: 20px !important;
    }
    .start-sheduler-btn {
        background-size: 22px !important;
        width: 22px;
        height: 22px;
        top: 1px;
    }
}

.k-svg-icon > svg {
    display: none;
}

.main-assign-dashlet-accordian {
    border-radius: 10px !important;
    border: 1px solid #e6e9f6 !important;
    background: rgba(216, 219, 234, 0.15) !important;
    margin-bottom: 10px !important;
    padding: 11px 20px !important;
    .k-panelbar-group {
        margin-top: 10px;
    }
    .k-link {
        background: transparent !important;
        color: inherit !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        &:hover {
            background: inherit;
        }
        .k-expander-content {
            padding: 20px;
            padding-top: 0;
        }
        .k-icon {
            &::after,
            &::before {
                display: none;
            }
        }
        .k-expander-title {
            text-transform: capitalize;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        .assign-num {
            padding: 4px 5px 3px 5px;
            background: #001489;
            color: #fff;
            border-radius: 3px;
            font-size: 9px;
            font-weight: 600;
            margin-left: 12px;
            height: 15px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: -3px;
            line-height: 0;
        }
        .assign-elips {
            width: 5px;
            height: 5px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 4px;
            position: relative;
            top: -1px;
        }
        .green-elips.assign-elips {
            background: #1c9240;
        }
        .k-panelbar-item-text {
            padding-right: 10px;
        }
    }
}

.main-dashlet-box .k-panelbar > .k-item + .k-item,
.main-dashlet-box .k-panelbar > .k-panelbar-header + .k-panelbar-header {
    border-top-width: 0px;
}

.k-expander.k-focus {
    box-shadow: none;
}

.main-assign-dashlet-accordian.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded) {
    border-top-width: 1px;
}

.main-assign-dashlet-accordian .k-i-chevron-down {
    background-image: url("/assets/images/down-angle-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-size: 18px;
    cursor: pointer;
}

.main-assign-dashlet-accordian .k-i-chevron-up {
    background-image: url("/assets/images/down-angle-blue.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    background-size: 18px;
    transform: rotate(180deg);
    cursor: pointer;
}

.k-animation-container .auto-complete-search .k-list-content .k-list-ul .k-list-item:hover {
    background-color: transparent;
}

.k-animation-container .auto-complete-search .k-list-content .k-list-ul .k-list-item .k-list-item-text {
    color: #00041b;
    font-family: League Spartan;
    font-size: 16px;
    font-weight: 600;
    &:hover {
        color: #001489;
    }
}

.main-dashlet-box .custom-scrolbar {
    overflow-y: auto;
    margin-bottom: 8px;
}

.main-assign-dashlet-accordian .k-grid table {
    min-width: 510px !important;
}

.align-middle {
    vertical-align: middle !important;
}

// .k-grid-content{
// overflow-y: auto !important;
// }

@media screen and (min-width: 1440px) {
    .main-dashlet-box {
        .fs-12 {
            font-size: 14px;
        }
        .fs-11 {
            font-size: 13px;
        }
    }
}

.main-trip-label-box {
    position: relative;
    padding-right: 36px;
    .scenario-history-img {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.leg-list-table {
    .k-grid-header .k-header[aria-colindex="13"] .k-link {
        justify-content: center;
    }
    .k-grid-header .k-header[aria-colindex="14"] .k-link {
        justify-content: center;
    }
}

.scenario-operator-list-table{
    .k-grid-header .k-header[aria-colindex="5"] .k-link {
        justify-content: center;
    }
    .k-grid-header .k-header[aria-colindex="13"] .k-link {
        justify-content: center;
    }
}

.active-log-table {
    padding-bottom: 140px;
    .k-grid-header {
        border-top: 1px solid #e6e9f6;
        .k-header {
            padding-bottom: 0;
            .k-link .k-column-title {
                font-size: 10px;
                padding-top: 6px;
                padding-bottom: 4px;
                text-transform: capitalize;
            }
            &:first-child {
                border-left: 1px solid transparent !important;
            }
            &:last-child {
                border-right: 1px solid transparent !important;
            }
        }
    }
    .k-grid-header .k-header:last-child .k-column-title {
        padding-right: 0px;
    }
    .k-master-row td {
        font-size: 12px;
        height: 42px;
        min-height: 42px;
        vertical-align: top;
        &:first-child {
            font-weight: 600;
        }
        &:last-child {
            text-align: center;
        }
    }
    .k-table-td:first-child {
        font-weight: 600;
    }
}

.active-log-modal .k-dialog .k-window-content {
    overflow: hidden;
}

.btn-large-padding {
    padding-left: 26px;
    padding-right: 26px;
}

.active-log-modal .k-dialog .model-footer-action {
    box-shadow: none;
}

.jets-icon {
    width: 35px;
    height: 16px;
    border-radius: 38px;
    background: #e6e9f6;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.k-grid-header .k-header:last-child {
    border-right: 1px solid transparent !important;
}

.data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data .filter-middle .quick-filter-sec {
    flex-wrap: wrap;
    height: unset;
    gap: 10px;
}

.data-list-view-wrapper .data-grid-filter-sec .filtered-btn-data .filter-middle .quick-filter-sec .quick-filter-box.close {
    margin-right: 0;
}

.add-contact-details-model.contact-view-modal .k-dialog .k-window-content {
    padding: 0px 24px 24px 24px;
    .status-title-box {
        border-radius: 4px;
        background: #e1e4f3;
        height: 32px;
        padding: 4px 10px;
        position: relative;
        padding-left: 24px;
        &.primary {
            border: 1px solid #00106e;
            color: #00106e;
        }
        &.light-blue {
            border: 1px solid #9bcbeb;
            color: #608aa6;
            background: #eef5fa;
        }
        span{
            &.light-blue-elips-icon{
                &::before{
                    background: #7AA5C1;
                }
        }
        &::before {
            content: "";
            position: absolute;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
        &.light-blue {
            &::before {
                background: #7aa5c1;
            }
        }
        &.new-contact {
            &::before {
                background: #9BCBEB;
            }
        }
        &.initiated {
            &::before {
                background: #FFA722;
            }
        }
        &.potential-client {
            &::before {
                background: #1C9240;
            }
        }
        &.client {
            &::before {
                background: #001489;
            }
        }
        &.rejected {
            &::before {
                background: #D14141;
            }
        }
        &.draft {
            &::before {
                background: #B4B6C3;
            }
        }
        &.scheduling {
            &::before {
                background: #FFA722;
            }
        }
        &.quote-review {
            &::before {
                background: #FDE50E;
            }
        }
        &.client-review {
            &::before {
                background: #0656CE;
            }
        }
        &.accepted {
            &::before {
                background: #1C9240;
            }
        }
        &.partially-assigned {
            &::before {
                background: #ffffff;
                border: 2px solid #001489;
            }
        }
        &.assignment-made {
            &::before {
                background: #001489;
            }
        }
        &.ongoing-trip {
            &::before {
                background: #9BCBEB;
            }
        }
        &.trip-review {
            &::before {
                background: #FCCF8C;
            }
        }
        &.finance-review {
            &::before {
                background: #949DD7;
            }
        }
        &.finalized {
            &::before {
                background: #000C52;
            }
        }
        &.cancelled {
            &::before {
                background: #D14141;
            }
        }

    }
    }
    .main-progress-box {
        display: flex;
        align-items: center;
        .main-circle-progress {
            position: relative;
            color: #000c52;
            .client-info {
                font-size: 10px;
                font-weight: 600;
                position: absolute;
                top: 15px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .text-percentage {
            transform: translateY(7px);
            color: #000c52;
            font-weight: 600;
        }
        .progress-list {
            margin-left: 12px;
            .ellips {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                display: inline-block;
                &.primary-blue {
                    background: #001489;
                }
                &.light-blue {
                    background: #dde1f6;
                }
            }
        }
    }
}

.client-trip-status{
    .trip-status-elips-icon{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
        &.draft{
            background: #B4B6C3;
        }
        &.scheduling{
            background: #FFA722;
        }
        &.quote-review{
            background: #FDE50E;
        }
        &.client-review{
            background: #0656CE;
        }
        &.accepted{
            background: #1C9240;
        }
        &.partially-assigned{
            background: #ffffff;
            border: 1px solid #001489;
        }
        &.assignment-made{
            background: #001489;
        }
        &.ongoing-trip{
            background: #9BCBEB;
        }
        &.trip-review{
            background: #FCCF8C;
        }
        &.finance-review{
            background: #949DD7;
        }
        &.finalized{
            background: #000C52;
        }
        &.cancelled{
            background: #D14141;
        }
    }
}

.client-profile-accordian {
    .k-panelbar-item-text {
        width: 100%;
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    &.k-panelbar {
        border-radius: 5px;
    }
    .k-content {
        background: transparent;
    }
    .k-link {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.client-profile-accordian.trip {
    &.k-panelbar {
        display: grid;
        gap: 20px;
        border: none;
    }
    .k-content {
        background: transparent;
        border: 1px solid #dee2e6;
        color: #212529;
        border-radius: 5px;
        background-color: #ffffff;
    }
}

.client-profile-accordian.k-panelbar > .k-item > .k-link,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link {
    color: inherit;
    background-color: transparent;
    padding: 0;
}

.client-profile-accordian.k-panelbar > .k-item > .k-link.k-selected,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link.k-selected {
    color: inherit;
    background-color: transparent;
    padding: 0;
}

.client-profile-accordian.k-panelbar .k-panelbar-item {
    padding: 15px 20px;
}

.client-profile-accordian.k-panelbar > .k-item > .k-link:hover,
.client-profile-accordian.k-panelbar > .k-item > .k-link.k-hover,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link:hover,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link.k-hover {
    background-color: inherit;
}

.client-profile-accordian.k-panelbar .k-icon,
.client-profile-accordian.k-panelbar > .k-item > .k-link .k-panelbar-item-icon,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link .k-icon,
.client-profile-accordian.k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
    color: #001489;
    font-size: 16px;
    cursor: pointer;
    margin-left: 5px;
}

.highlight-blue {
    background: rgb(230, 233, 246) !important;
}
.circle {
    transition: all 0.7s ease-in-out;
}

.main-client-trip-table {
    max-height: 165px;
    overflow-y: auto;
    overflow-x: hidden;
}

.margin-per-line {
    display: inline-block;
    margin-left: 15px;
    padding-left: 15px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        display: inline-block;
        width: 1px;
        height: 30px;
        top: -9px;
        left: 0px;
        background: #00041b;
    }
}

.k-dialog-wrapper {
    right: 0;
    left: auto;
}

.bg-preloader-inner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.align-sub {
    vertical-align: sub;
}

.main-status-icon {
    .elips-status-icon {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
        position: relative;
        &.in-queue {
            background: #949dd7;
        }
        &.draft {
            background: #B4B6C3;
        }
        &.scheduler-assigned {
            background: #ffa722;
        }
        &.quote-review {
            background: #f5de0d;
        }
        &.client-review {
            background: #0656ce;
        }
        &.accepted {
            background: #1c9240;
        }
        &.tail-assigned {
            background: #001489;
        }
        &.in-transit {
            background: #9bcbeb;
        }
        &.completed {
            background: #000c52;
        }
        &.cancelled {
            background: #d14141;
        }
    }
}

.contact-view-modal ~ .custom-popover {
    z-index: 99999;
}

.custom-elips {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
    position: relative;
    top: -1px;
    &.dark-blue-elips {
        background: #000c52;
    }
}

.k-popup > .k-timeselector .k-button {
    &:hover {
        outline: none !important;
    }
}

.k-popup > .k-timeselector .k-button-flat-primary {
    &:hover {
        border: none !important;
    }
}

.main-dashlet-box .k-grid-content {
    overflow-y: auto;
}

.source-manually-table {
    height: calc(100vh - 370px);
    .k-grid-header .k-header[aria-colindex="2"] .k-link {
        justify-content: center;
    }
}

.text-break-word {
    word-wrap: break-word;
}

.text-word-break {
    word-break: break-word
}

.d-list-item {
    display: list-item;
    list-style-type: none;
}

.text-break-word {
    word-wrap: break-word;
}

.k-dialog-wrapper ~ .tooltip {
    z-index: 99999;
}

.k-dialog-wrapper ~ .custom-popover {
    z-index: 999991;
}

.vh-95 {
    height: 95vh;
}

.k-timepicker-popup{
    .k-time-footer {
        margin-top: 0 !important;
    }
    .k-time-part {
        height: 200px;
        overflow: hidden;
    }
    .k-reset {
        transform: unset !important;
    }
}
.arrow-icon-can {
    transform: rotate(180deg);
    height: 8px;
    font-size: 21px;
    vertical-align: -webkit-baseline-middle;
}

.arrow-icon-can.open-dropdown {
    transform: rotate(0deg);
    height: 8px;
    font-size: 21px;
    vertical-align: top;
}

.hide-shorting:after{
    display: none;
}

.k-animation-container{
    visibility: unset !important;
}

body:has(> div.k-dialog-wrapper){
    overflow: hidden !important;
}

.graph-modal .k-dialog .k-window-content{
    overflow-y: scroll;
}

.split-trip-checkbox input {
    position: relative;
    top: -1px;
}

.notes-trip-client {
    padding: 6px 4px;
    margin-bottom: 10px;
}

.main-status-box{
    .text{
        flex: auto;
    }
}

.scenario-disabled{
    pointer-events: none;
    .disabled-item{
        opacity: 0.5;
    }
    .upDownIcon{
        svg{
            opacity: 1;
            pointer-events: auto;
        }
    }
    .disabled-item-accordian{
        .k-panelbar-item-text{
            opacity: 0.5;
        }
        .k-animation-container{
            opacity: 0.5;
        }
        .k-icon{
            opacity: 1;
            pointer-events: auto;
        }
    }
    .k-grid {
        pointer-events: auto;
        .k-grid-header {
            pointer-events: none;
        }
    }
    .k-grid table {
        pointer-events: none;
    }
}

.avinodeInProgress {
    border: 2px solid red !important;
}

.status-strips-gray {
    border-left: 4px solid rgba(210, 218, 227, 0.75) !important;
    position: relative;
}

.status-strips-yellow {
    border-left: 4px solid rgba(255, 167, 34, 1) !important;
    position: relative;
}

.status-strips-red {
    border-left: 4px solid rgba(209, 64, 64, 1) !important;
    position: relative;
}

.status-strips-green {
    border-left: 4px solid rgba(23, 222, 43, 1) !important;
    position: relative;
}

.pricing-recom-tooltip:hover {
    z-index: 99999999999 !important;
    cursor: pointer;
}

.jets-table-bg{
    background: rgba(216, 219, 234, 0.40) !important;
    box-shadow: 0px 4px 10px 0px rgba(169, 170, 181, 0.25) !important;
    &:hover{
        background: inherit !important;
        td{
            background: rgba(216, 219, 234, 0.40) !important;
        }
    }
    td{
        background: transparent !important;
    }
}
.addOprator-input input:focus {
    outline: none !important; /* Remove default focus outline */
    background-color: #7881b9  !important; /* Add a visual cue for focus */
  }

tr.addOprator-popup td:has(.addOprator-input input:focus),
tr.addOprator-popup td:has(.addOprator-input input:focus)~td{
  outline: none !important; /* Remove default focus outline */
  background-color: #eee  !important; /* Add a visual cue for focus */
}
.allChecked input:focus {
    outline: none !important; /* Remove default focus outline */
    background-color: #7881b9  !important; /* Add a visual cue for focus */
}


.white-space-pre {
    white-space: pre !important;
}
.main-pop{
    position: absolute;
    margin-left: calc(0px - 150px);
    z-index: 99 !important;
}

.custom-popover.custom-popovers{
padding: 0px !important;
}

.hideBtn{
    width: 0px;
    height: 0px;
    visibility: hidden;
    position: absolute;
    top: 0px;
}
@media (max-width: 320px) {
    .flex-column-on-small {
      flex-direction: column !important;
    }
  }

.custom-dropdown-item{
    font-weight: 600;
    font-size: 14px;
    color: $primary-dark-blue;

    &:hover {
        background-color: #f0f2f9;
    }
}

.do-not-use {
    background-image: url('/assets/images/do-not-use.svg') !important;
    background-repeat: no-repeat !important;
    width: 15px;
    height: 15px;
    background-position: center;
}
