@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
* {
    padding: 0;
    margin: 0;
    font-family: "League Spartan", sans-serif;
}
body {
    font-family: "League Spartan", sans-serif;
}

// variables
$leagueSpartanFont: "League Spartan", sans-serif;
$AlluraFont: "Allura", cursive;
$light-grey: #2d3f59;
$purple-grey: #82869B;
$dark-grey: #00041b;
$text-dark-black-color: #00041b;
$primary-dark-blue: #00106e;
$primary-light-blue: #001489;
$deep-blue: #000c52;
$grey-secondary-button-color: #667080;
$link-hover: #061c9e;
$dark-blue: #000837;
$label-color: #82869b;
$white-color: #ffffff;
$white90: #cccccc;
$grey-light-border: rgba(210, 218, 227, 0.75);
//Variables end

// status color
$error-color: #d14141;
$warning-color: #ffa722;
$info-color: #0656ce;
$success-color: #1c9240;
