.icreon-sidebar {
    .sidebar {
        height: calc(100% - 60px);
        width: 0;
        position: fixed;
        z-index: 9999;
        top: 72px;
        left: 0;
        background: #000837;
        overflow-x: hidden;
        transition: 0.8s;
        min-width: 52px;
        padding: 8px 12px;
        &.green-bg {
            background: #008001;
        }
        hr {
            background: #d2dae3;
            margin: 10px -5px;
            opacity: 0.6;
            border: none;
            height: 1px;
        }
        // .mobile-sidebar-links-group {
        //     padding-right: 15px;
        //     padding-left: 5px;
        // }
    }
    .sidebar {
        .left-menu-link {
            display: flex;
            align-items: center;
            padding: 6px 0px 6px 0px;
            cursor: pointer;
            span.nav-icon {
                height: 28px;
                width: 32px;
                min-width: 32px;
                min-height: 28px;
                text-decoration: none;
                display: block;
                transition: 0.6s;
                text-align: center;
                background-repeat: no-repeat !important;
                background-size: 17px;
                background-position: center;
                cursor: pointer;
                margin-left: -5px;
            }
            .menu1 {
                background-image: url("/assets/images/menu-icon1.svg");
            }
            .menu2 {
                background-image: url("/assets/images/menu-icon2.svg");
            }
            .menu3 {
                background-image: url("/assets/images/menu-icon3.svg");
            }
            .menu4 {
                background-image: url("/assets/images/menu-icon4.svg");
            }
            .menu5 {
                background-image: url("/assets/images/menu-icon5.svg");
            }
            .menu6 {
                background-image: url("/assets/images/menu-icon6.svg");
                background-size: 22px !important;
            }
            .menu7 {
                background-image: url("/assets/images/menu-icon7.svg");
            }
            .menu8 {
                background-image: url("/assets/images/menu-icon8.svg");
            }
            .menu9 {
                background-image: url("/assets/images/menu-icon9.svg");
            }
            .menu10 {
                background-image: url("/assets/images/menu-icon10.svg");
            }
            .menu11 {
                background-image: url("/assets/images/menu-icon11.svg");
            }

            &:hover,
            &:focus {
                span.nav-icon {
                    background-color: #e3ecff;
                    border-radius: 4px;
                }
                .menu1 {
                    background-image: url("/assets/images/menu-icon1_hv.svg");
                }
                .menu2 {
                    background-image: url("/assets/images/menu-icon2_hv.svg");
                }
                .menu3 {
                    background-image: url("/assets/images/menu-icon3_hv.svg");
                }
                .menu4 {
                    background-image: url("/assets/images/menu-icon4_hv.svg");
                }
                .menu5 {
                    background-image: url("/assets/images/menu-icon5_hv.svg");
                }
                .menu6 {
                    background-image: url("/assets/images/menu-icon6_hv.svg");
                }
                .menu7 {
                    background-image: url("/assets/images/menu-icon7_hv.svg");
                }
                .menu8 {
                    background-image: url("/assets/images/menu-icon8_hv.svg");
                }
                .menu9 {
                    background-image: url("/assets/images/menu-icon9_hv.svg");
                }
                .menu10 {
                    background-image: url("/assets/images/menu-icon10_hv.svg");
                }
                .menu11 {
                    background-image: url("/assets/images/menu-icon11_hv.svg");
                }
                label {
                    opacity: 0.6;
                }
            }
            .sub-dropdown-menu {
                width: 100%;
                background: transparent;
                border: none;
                .k-link {
                    width: 100%;
                    background: transparent !important;
                    padding: 0;
                    box-shadow: none;
                    cursor: pointer;
                    &:focus,
                    &:hover {
                        box-shadow: none;
                        outline: none;
                        border: none;
                    }
                    .k-panelbar-item-text {
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }
                    .k-icon {
                        color: #fff;
                        font-size: 18px;
                        visibility: hidden;
                    }
                }
                .k-animation-container {
                    .k-content {
                        border: none;
                        background: transparent;
                        .left-sub-menu {
                            padding-left: 44px;
                            ul {
                                margin: 0;
                                padding: 0;
                                list-style: none;
                                //border-bottom: 1px solid #151b3c;
                            }
                            li {
                                color: #fff;
                                //font-weight: 600;
                                font-weight: 500;
                                font-size: 14px;
                                opacity: 0.6;
                                letter-spacing: 0.3px;
                                line-height: 15px;
                                padding: 8px 0;
                                white-space: nowrap;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .create-btn {
                background-repeat: no-repeat !important;
                background-size: 14px;
                background-position: center;
                background-image: url("/assets/images/pluse-white.svg");
                background-color: rgba(193, 204, 228, 0.2);
                border-radius: 4.53846px;
                width: 32px;
                height: 32px;
                border: none;
                padding: 0;
                position: absolute;
                right: 0;
                top: 7px;
                z-index: 99;
                // visibility: hidden;
                &:focus {
                    box-shadow: none;
                }
            }
            position: relative;
            min-width: 200px;
        }
        label {
            margin: 0;
            padding-left: 15px;
            color: #fff;
            font-weight: 600;
            font-size: 14px;
            line-height: 13px;
            cursor: pointer;
            transition: 0.6s;
            visibility: hidden;
            white-space: nowrap;
        }
        .mobile-sidebar-header {
            display: none;
        }
    }
    @media screen and (min-width: 768px) {
        .sidebar:hover {
            min-width: 220px;
            label,
            .k-link .k-icon,
            .create-btn {
                visibility: visible !important;
            }
        }
    }
    .sidebar .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
        display: none;
    }
    #main {
        padding: 20px 15px 20px 72px;
    }
}

/*  ###########   Responsive Queries  ############ */

@media screen and (max-width: 767px) {
    .icreon-sidebar {
        #main {
            padding: 12px;
        }
        .sidebar {
            top: 0px;
            z-index: 1000;
            height: 100%;
            min-width: 0px;
            margin-left: -25px;
            .mobile-sidebar-links-group {
                //padding-right: 30px;
                padding-right: 10px;
                padding-left: 0;
            }
            .mobile-sidebar-header {
                display: flex;
                background-color: #fff;
                width: calc(100% + 24px);
                min-width: 300px;
                margin: -10px -12px 12px -12px;
                justify-content: space-between;
                align-items: center;
                padding: 12px 15px 12px 20px;
                .sidebar-logo-sec {
                    width: 150px;
                    img {
                        width: 100%;
                    }
                }
                .header-close-btn {
                    background: url("/assets/images/close-blue.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    border: none;
                    background-size: 16px;
                    width: 32px;
                    height: 32px;
                    display: inline-block;
                    padding: 0;
                    &:focus,
                    &:hover,
                    &:active {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .left-menu-link {
                display: flex;
                align-items: center;
                padding: 6px 0px 6px 0px;
                cursor: pointer;
                span.nav-icon {
                    margin-left: 0;
                }
                &:hover,
                &:focus {
                    label {
                        opacity: 0.6;
                    }
                }
                .sub-dropdown-menu {
                    .k-link {
                        .k-icon {
                            visibility: visible;
                        }
                    }
                }
                .sub-dropdown-menu .k-animation-container .k-content .left-sub-menu {
                    padding-left: 48px;
                }
            }
            label {
                visibility: visible;
            }
        }
    }
}
.icreon-sidebar .sidebar .left-menu-link.active {
    span.nav-icon {
        background-color: #e3ecff;

        border-radius: 4px;
    }

    .menu1 {
        background-image: url("/assets/images/menu-icon1_hv.svg");
    }

    .menu2 {
        background-image: url("/assets/images/menu-icon2_hv.svg");
    }

    .menu3 {
        background-image: url("/assets/images/menu-icon3_hv.svg");
    }

    .menu4 {
        background-image: url("/assets/images/menu-icon4_hv.svg");
    }

    .menu5 {
        background-image: url("/assets/images/menu-icon5_hv.svg");
    }

    .menu6 {
        background-image: url("/assets/images/menu-icon6_hv.svg");
    }

    .menu7 {
        background-image: url("/assets/images/menu-icon7_hv.svg");
    }

    .menu8 {
        background-image: url("/assets/images/menu-icon8_hv.svg");
    }

    .menu9 {
        background-image: url("/assets/images/menu-icon9_hv.svg");
    }

    .menu10 {
        background-image: url("/assets/images/menu-icon10_hv.svg");
    }

    .menu11 {
        background-image: url("/assets/images/menu-icon11_hv.svg");
    }

    label {
        opacity: 0.6;
    }
}
